// this is the projectReducer

import {
    ORDERS_WITH_IMAGES_REQUEST,
    ORDERS_WITH_IMAGES_SUCCESS,
    ORDERS_WITH_IMAGES_FAIL,
    ORDERS_WITH_IMAGES_RESET, // Assuming you might want a reset action
  } from '../constants/projectContants';
  
  const initialState = {
    loading: false,
    orders: [],
    error: null,
  };
  

  const ordersWithImagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ORDERS_WITH_IMAGES_REQUEST:
        return { ...state, loading: true };
      case ORDERS_WITH_IMAGES_SUCCESS:
        console.log("Payload:", action.payload); // Log payload
        return { ...state, loading: false, orders: action.payload };
      case ORDERS_WITH_IMAGES_FAIL:
        return { ...state, loading: false, error: action.payload };
      case ORDERS_WITH_IMAGES_RESET:
        return initialState; // Reset state to initial state
      default:
        return state;
    }
  };
  
  

  export default ordersWithImagesReducer;