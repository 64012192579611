import { CART_ADD_ITEM, CART_EMPTY, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS } from "../constants/cartContants";

export const cartReducer = (state = { cartItems: [], shippingAddress: {}, paymentMethod: null }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
  const newItem = action.payload;
  const existingItemIndex = state.cartItems.findIndex(
    (item) => item.product === newItem.product && item.variant === newItem.variant
  );

  if (existingItemIndex !== -1) {
    // If the item already exists in the cart with the same product ID and variant, update its quantity
    const updatedCartItems = [...state.cartItems];
    updatedCartItems[existingItemIndex].qty += newItem.qty;

    return {
      ...state,
      cartItems: updatedCartItems,
    };
  } else {
    // If it's a new item, add it to the cart
    return {
      ...state,
      cartItems: [...state.cartItems, newItem],
    };
  }

  case CART_REMOVE_ITEM:
    const { id: productId, variant } = action.payload;
    const itemIndex = state.cartItems.findIndex((item) => item.product === productId && item.variant === variant);
  
    if (itemIndex !== -1) {
      // If the item exists in the cart, remove it
      const updatedCartItems = [...state.cartItems];
      updatedCartItems.splice(itemIndex, 1); // Remove the item at the found index
  
      return {
        ...state,
        cartItems: updatedCartItems,
      };
    } else {
      // If the item does not exist in the cart, return the current state
      return state;
    }
  
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case CART_EMPTY:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
