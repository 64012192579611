import React from 'react';

const Message = ({ variant = 'info', children }) => {
  const alertVariants = {
    info: '',
    success: 'bg-green-500 text-white',
    warning: 'bg-yellow-500 text-black',
    danger: 'bg-red-500 text-white',
  };

  const chosenVariant = alertVariants[variant] || alertVariants.info; // Fallback to info

  return (
    <div className={`m-5  text-default ${chosenVariant}`}>
      {children}
    </div>
  );
};

export default Message;