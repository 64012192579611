import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { saveShippingAddress } from '../actions/cartActions';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import useArtistDetails from '../hooks/useArtistDetails';
import useUserProfile from '../hooks/useUserProfile';


const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress, cartItems } = cart;

  const firstCartItem = cartItems.length > 0 ? cartItems[0] : null;
  const user = firstCartItem ? firstCartItem.user : null;

  const { artistId } = useParams(); // Extract artist ID from URL params
  const { loading, error, artist } = useArtistDetails(user);

  const [artistStreet, setArtistStreet] = useState('');
  const [artistStreetNumber, setArtistStreetNumber] = useState('');
  const [artistCity, setArtistCity] = useState('');
  const [artistPostalCode, setArtistPostalCode] = useState('');
  const inputStyle = "py-2 px-3 w-full nav dropdown";

  useEffect(() => {
    if (artist && artist.address) {
      setArtistStreet(artist.address.street);
      setArtistStreetNumber(artist.address.streetNumber);
      setArtistCity(artist.address.city);
      setArtistPostalCode(artist.address.postalCode);
      console.log("Artist's street:", artist.address.street);
      console.log("Artist's street number:", artist.address.streetNumber);
      console.log("Artist's city:", artist.address.city);
      console.log("Artist's postal code:", artist.address.postalCode);
    }
  }, [artist]);

  const [address, setAddress] = useState(shippingAddress.address || '');
  const [city, setCity] = useState(shippingAddress.city || '');
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode || '');
  const [country, setCountry] = useState(shippingAddress.country || '');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addressInputRef = useRef(null);

  useEffect(() => {
    const loadGooglePlacesAutocomplete = () => {
      if (!window.google) {
        console.error("Google Places API script not loaded");
        return;
      }

      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
        types: ["geocode"],
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.address_components) {
          return;
        }
        const getAddressComp = (type) => {
          return place.address_components.find(comp => comp.types.includes(type))?.long_name || '';
        };

        setAddress(place.formatted_address);
        setCity(getAddressComp("locality"));
        setPostalCode(getAddressComp("postal_code"));
        setCountry(getAddressComp("country"));
      });
    };

    loadGooglePlacesAutocomplete();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    const artistStreetName = artistStreet; // Store the street name
    const artistStreetNum = artistStreetNumber; // Store the street number
    const artistCityName = artistCity; // Store the city name

    const artistFullAddress = `${artistStreetName} ${artistStreetNum}, ${artistCityName}`;

    try {
      const { data } = await axios.post('/api/distance/calculate', { origin: artistFullAddress, destination: address });
      const durationInMinutes = data.rows[0].elements[0].duration.value;
      const durationInHours = Math.ceil(durationInMinutes / 60 / 60);

      dispatch(saveShippingAddress({ address, city, postalCode, country, timeToDrive: durationInHours }));
      navigate('/payment');
    } catch (error) {
      console.error('Error calculating time:', error);
      alert('Failed to calculate driving time. Please check console for details.');
    }
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <div className='p-5'>
        <form onSubmit={submitHandler}>
          <p className="text-5xl ">Shipping</p>

          <div className="">
            <label className="font-bold py-2" htmlFor="address">
              Address
            </label>
            <input
              ref={addressInputRef}
              type='text'
              id="address"
              placeholder='Enter address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              className={inputStyle}
            />
          </div>

          <div className="">
            <label className="font-bold py-2" htmlFor="city">
              City
            </label>
            <input
              type='text'
              id="city"
              placeholder='Enter city'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              className={inputStyle}
            />
          </div>

          <div className="">
            <label className="font-bold py-2" htmlFor="postalCode">
              Postal Code
            </label>
            <input
              type='text'
              id="postalCode"
              placeholder='Enter postal code'
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              required
              className={inputStyle}
            />
          </div>

          <div className="">
            <label className="font-bold py-2" htmlFor="country">
              Country
            </label>
            <input
              type='text'
              id="country"
              placeholder='Enter country'
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
              className={inputStyle}
            />
          </div>

          <div className='flex justify-end'>
            <button type='submit' className="btn w-40 ml-auto mt-10">
              Continue
            </button>
          </div>
        </form>
      </div>
    </FormContainer>
  );
};

export default ShippingScreen;
