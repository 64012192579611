// components/CartTable.js
import React from 'react';
import { Link } from 'react-router-dom';

const CartTable = ({ cartItems, removeFromCartHandler, deleteItem }) => {
  return (
    <div className="w-full h-full ">
      <div className="w-full ">
        <div className="grid grid-cols-12 items-center nav text-default">
          <div className="col-span-1 nav-right font-bold">
            {/* Header for the new column */}
          </div>
          <p className="col-span-3 flex justify-center items-center font-bold">
            Cart
          </p>
          <p className="col-span-6 flex justify-center items-center font-bold">
            Item
          </p>
          <p className="col-span-2 flex justify-center items-center font-bold">
            Price Item(s)
          </p>
        </div>
        {cartItems.map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-6 lg:grid-cols-12 items-center nav-bottom nav-right nav-left text-default my-10 lg:my-0 "
          >
            
              
              <div className="col-span-1 flex flex-col justify-center items-center h-full nav-top lg:nav-top-hidden">
                {/* Your icons and buttons */}
                {deleteItem && (
                <button
                  type="button"
                  variant="light"
                  onClick={() => removeFromCartHandler(item.product, item.variant)}
                  className="custom-icon"
                >
                  <svg
  xmlns="http://www.w3.org/2000/svg"
  className="h-6 w-6"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    d="M6 18L18 6M6 6l12 12"
  />
</svg>
                </button>
              )}
              </div>

            
            <div className="col-span-5 lg:col-span-3 flex justify-center items-center nav-left h-full nav-top lg:nav-top-hidden">
              <div className="h-1/3">
                <img
                  src={item.images[0]?.ItemMainImage}
                  alt={item.name}
                  className="h-full"
                />
              </div>
            </div>
            <div className="col-span-4 lg:col-span-6 flex lg:nav-left flex-col justify-start items-start h-full p-3 nav-top lg:nav-top-hidden">
              <Link to={`/product/${item.product}`} className="w-full text-3xl pb-2">
                {item.name}
              </Link>
              {item.variant && (
                <div className="w-full p-2 nav">
                  {Object.entries(item.variant).map(([key, value]) => (
                    <div key={key} className={`flex w-full`}>
                      <div>
                        <span className={`text-default w-full`}>{key}</span>
                      </div>
                      <div className="ml-auto">
                        <span className={`ml-auto font-thin `}>{value}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="col-span-2 flex flex-col justify-center items-end nav-left h-full nav-top lg:nav-top-hidden">
              <p className="mx-auto font-bold">€{(item.qty * item.price).toFixed(2)}</p>
              
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartTable;
