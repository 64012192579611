import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import Message from "../components/message";
import Loader from "../components/loader";
import { emptyCart } from "../actions/cartActions";
import ProductScreenDrag from "../components/ProductScreenDrag";
import ProductWithVariants from "../components/ProductWithVariants";
import ArtistDetails from "../components/ArtistDetails";

const OrderScreenTest = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const containerClass = "py-2 text-default nav-under ";
  const inputStyles = "border p-2 w-full"; // Removed 'rounded-md' class
  const fontThin = "font-thin pl-5 text-default";
  const TextTitle = "text-5xl text-default";

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: succesDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [clickedIndex, setClickedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleButtonClick = (index) => {
    setClickedIndex(index);
  };

  const handleButtonHover = (index) => {
    setHoveredIndex(index);
  };

  const handleButtonLeave = () => {
    setHoveredIndex(null);
  };

  const productSelected =
    clickedIndex !== null && order && order.orderItems && clickedIndex < order.orderItems.length
      ? clickedIndex
      : 0;

  if (!loading && order && order.orderItems) {
    // Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.length > 0
        ? order.orderItems[productSelected].price *
            order.orderItems[productSelected].qty
        : 0
    );
  }

  useEffect(() => {
    if (!order || order._id !== id) {
      dispatch(getOrderDetails(id));
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
    } else if (order && order.isPaid) {
    }
  }, [dispatch, id, order, succesDeliver]);

  const handlePayment = async () => {
    try {
      // Dispatch the payOrder action with the order ID and payment result
      await dispatch(
        payOrder(order._id, {
          id: "PAYMENT_ID_FROM_PAYMENT_GATEWAY", // Replace with the actual payment ID from the payment gateway
          status: "COMPLETED", // Assuming payment is completed
          update_time: new Date().toISOString(), // Current timestamp
          payer: {
            email_address: order.user.email, // Use the email address of the user who placed the order
          },
        })
      );

      // If payment is successful, fetch updated order details
      dispatch(getOrderDetails(order._id));
      // Empty the cart after successful payment
      dispatch(emptyCart());
    } catch (error) {
      // Handle payment failure
      console.error("Payment failed:", error);
      // Optionally, display an error message to the user
    }
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  // Function to determine the status of the order
  const getStatusOrder = () => {
    if (order.isDelivered) {
      return "Delivered";
    } else if (order.isPaid) {
      return "Paid";
    } else {
      return "To be Paid";
    }
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div className="fixed inset-0 flex">
      <div className="container flex flex-col w-full h-full">
        {/* Upper row with light blue and light red sections, using 1/3 of total height */}
        <div className="flex w-full text-default">
          <div className="w-2/3 h-24"></div>
          <div className="w-1/3 h-24 "></div>
        </div>
        {/* Upper row with light blue and light red sections, using 1/3 of total height */}
        <div className="flex w-full nav-left nav-standard text-default">
          <div className="w-full flex flex-row relative px-5 py-2 justify-between">
          <p>Order: {id}</p>
          <ProductWithVariants
              orderItems={order.orderItems}
              productSelected={productSelected}
            />
            <div className="flex">
            
              <p>{getStatusOrder()}</p>
            </div>
            
            <div className="flex">
            {/* print the id number of the selected product */}
              <p>{order.orderItems[productSelected]._id}</p>
              
            

            </div>


          </div>
        </div>

        {/* Lower row with blue and red sections, using 2/3 of total height */}
        <div className="flex w-full h-full">
          <div
            className="nav-standard nav-left w-2/3 h-full relative flex flex-col justify-center"
            style={{
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/CompanyImages/logoDeKnalAchtergrond.png)", // Add linear gradient with 50% opacity over the background image
              backgroundSize: "50%", // Set background size to 50
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Set background repeat to no-repeat
              position: "relative",
            }}
          >
            {/* Inside the ProductScreenDrag component, pass the orderId as a prop, also receive a variant = {order}  */}
            
            
            <ProductScreenDrag
              variant="order"
              id={order.orderItems[productSelected]._id}
              selectedIndex={productSelected}
              order={id}
            />

            <div className="absolute top-0 left-0 flex items-center p-5">
              {order.orderItems.map((item, index) => (
                <button
                  key={index}
                  className={`btn mr-3 ${
                    clickedIndex === index ? "btn-negative" : ""
                  }`}
                  onClick={() => handleButtonClick(index)}
                >
                  {`Version ${index + 1}`}
                </button>
              ))}
            </div>

            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center">
              <span className="text-default text-2xl font-thin pr-3">←</span>
              <p className="text-default p-6 text-2xl font-thin">
                Drag to rotate
              </p>
              <span className="text-default text-2xl font-thin pl-3">→</span>
            </div>
          </div>
          <div className="w-1/3 h-full relative flex flex-col justify-end items-end nav-right">
            <div className="w-full h-auto nav-top">
              {/* Separate containers with different heights */}

              <div className={`h-auto flex flex-col justify-between`}>
                <div className="p-5 text-default nav-under space-y-1">
                  <p className={TextTitle}>Quote Product(s)</p>
                  <p className="font-thin">total price</p>
                  <div className="flex">
                    {<p className="text-2xl">{order.itemsPrice}</p>}
                    <p className={`${fontThin} text-2xl`}>{`(inc eur VAT)`}</p>
                  </div>
                  <p className="font-thin"></p>
                  <div className="w-full ml-auto flex items-end justify-end">
                    {" "}
                    {/* Added flex class */}
                    {!order.isPaid && (
                      <button
                        className="btn w-1/2 mt-4"
                        onClick={handlePayment}
                      >
                        Pay Now
                      </button>
                    )}
                    {userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                      <button
                        onClick={deliverHandler}
                        className="btn w-1/2 mt-4"
                      >
                        Mark as Delivered
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderScreenTest;
