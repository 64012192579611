//this is my store.js file
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer
} from './reducers/productReducers';
import { cartReducer } from './reducers/cartReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  artistDetailsReducer,
  artistListReducer
} from './reducers/userReducer';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer, // Added import for orderPayReducer
  orderListMyReducer,
  orderListReducer,
  orderDeliverReducer,
  productTopRatedReducer,
  orderArtistReducer,
} from './reducers/orderReducer';
import {chatReducer } from './reducers/chatReducer'
import ordersWithImagesReducer from './reducers/projectReducer';

// Combine all reducers into a single root reducer
const reducer = combineReducers({
productList: productListReducer,
productDetails: productDetailsReducer,
productDelete: productDeleteReducer,
productCreate: productCreateReducer,
productUpdate: productUpdateReducer,
productReviewCreate:productReviewCreateReducer,
productTopRated: productTopRatedReducer,


  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer, 
  userUpdate: userUpdateReducer,
  artistDetails: artistDetailsReducer,
  artistList: artistListReducer,
  

  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer ,// Included orderPayReducer in the root reducer
  orderListMy: orderListMyReducer,
  orderList:orderListReducer,
  orderDeliver: orderDeliverReducer,
  chat: chatReducer,
  ordersWithImages: ordersWithImagesReducer,
  artistOrders: orderArtistReducer,
  
});

// Retrieve cart items from local storage or set to an empty array
const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : [];

// Retrieve user information from local storage or set to null
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {};

// Set the initial state with cart items and user information from local storage
const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

// Array of middleware to be applied
const middleware = [thunk];

// Compose the Redux DevTools enhancer with the middleware
const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

// Create the Redux store with the root reducer, initial state, and enhancers
const store = createStore(reducer, initialState, composedEnhancers);

export default store;