//this is my userActions

import axios from 'axios'
import {
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCES,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCES,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCES,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCES,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCES,
  USER_LIST_RESET,
  USER_DELETE_SUCCES,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCES,
  USER_UPDATE_FAIL,
  ARTIST_DETAILS_REQUEST,
  ARTIST_DETAILS_SUCCES,
  ARTIST_DETAILS_FAIL,
  ARTIST_LIST_REQUEST,
  ARTIST_LIST_SUCCES,
  ARTIST_LIST_FAIL

} from "../constants/userConstants"

import { ORDER_LIST_MY_RESET } from '../constants/orderConstants'

// Action creator for user login
export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/users/login', { email, password }, config)

        dispatch({
            type: USER_LOGIN_SUCCES,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    dispatch({ type: ORDER_LIST_MY_RESET })
    dispatch({ type: USER_LIST_RESET}) 
}

// Action creator for user registration
export const register = (name, email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_REGISTER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/users', { name, email, password }, config)

        dispatch({
            type: USER_REGISTER_SUCCES,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCES,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}


export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_DETAILS_REQUEST });

        const { userLogin: { userInfo } } = getState(); // Corrected from {getState()}

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}` // Corrected to use backticks and Bearer with capital B
            }
        };

        const { data } = await axios.get(`/api/users/${id}`, config); // Corrected to use backticks

        dispatch({
            type: USER_DETAILS_SUCCES, // Remember the earlier typo correction; should be USER_DETAILS_SUCCESS
            payload: data
        });

    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
  
      const { userLogin: { userInfo } } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`, // Corrected to use backticks and Bearer with capital B
        },
      };
  
      const { data } = await axios.put(`/api/users/profile`, user, config); // Corrected to use backticks
  
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCES, // Corrected the spelling of 'SUCCESS'
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL, // Corrected the action type
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const ListUsers = () => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST});
  
      const { userLogin: { userInfo } } = getState();
  
      const config = {
        headers: {
          
          Authorization: `Bearer ${userInfo.token}`, // Corrected to use backticks and Bearer with capital B
        },
      };
  
      const { data } = await axios.get(`/api/users`, config); // Corrected to use backticks
  
      dispatch({
        type: USER_LIST_SUCCES, // Corrected the spelling of 'SUCCESS'
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL, // Corrected the action type
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const deleteUser = (id) => async (dispatch, getState) => { // Changed _id to id
    try {
        dispatch({ type: USER_DELETE_REQUEST});

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/users/${id}`, config); // Corrected to use backticks

        dispatch({
            type: USER_DELETE_SUCCES, 
        });
    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL, // Corrected the action type
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`/api/users/${user._id}`, user, config);

        dispatch({ type: USER_UPDATE_SUCCES });
        dispatch({ type: USER_DETAILS_SUCCES, payload: data });
    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getArtistDetails = (id) => async (dispatch) => {
    try {
      dispatch({ type: ARTIST_DETAILS_REQUEST });
  
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const { data } = await axios.get(`/api/users/artist/${id}`, config);
  
      dispatch({
        type: ARTIST_DETAILS_SUCCES,
        payload: data
      });
  
    } catch (error) {
      dispatch({
        type: ARTIST_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const ListArtist = () => async (dispatch, getState) => {
    try {
      dispatch({ type: ARTIST_LIST_REQUEST});

      const { data } = await axios.get(`/api/users/artists`); // Corrected to use backticks
  
      dispatch({
        type: ARTIST_LIST_SUCCES, // Corrected the spelling of 'SUCCESS'
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ARTIST_LIST_FAIL, // Corrected the action type
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };