import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InformationContainer from './InformationContainer';

const Reviews = () => {
  const navigate = useNavigate();

  const handleEmailClick = (orderData = null) => {
    // Optional: Pre-fill the email body with order details if provided
    let emailBody = `
      Hi De Knal Sales Team,

      I'm writing to leave a review.
    `;

    if (orderData) {
      emailBody += `

      Here's some information about my recent order (ID: (please fill in youre ID))
      `;
    }

    emailBody += `

      Please let me know if you have any questions or need further information from me.

      Thanks,
      [Your Name]
    `;

    // Encode the email body for safe inclusion in the URL
    const encodedEmailBody = encodeURIComponent(emailBody);

    // Open a new email window/tab with the recipient pre-filled and the email body set
    window.location.href = `mailto:sales@deknal?subject=Review&body=${encodedEmailBody}`;
  };

  return (
    <div className="container p-10 text-default">
      <div className="flex flex-row">
        <InformationContainer className="w-full bg-red-200 text-default">
          <div className='flex w-full'>
            <Link to='/profile' type="submit" className="btn w-40 mr-auto mb-10  text-default">
              Go Back
            </Link>
          </div>
          <div className='w-1/2'>
            <h2>Leave a Review</h2> <br/>
            <p>
              We appreciate your feedback! Share your experience with De Knal by leaving a review. You can also send us an email with any questions you might have.
            </p>
            <br />
          </div>

          

          {/* Optional: Button to pre-fill email with order data (if available) */}
          
            <button
              type="button"
              className="btn w-40 mt-2 mr-auto "
              onClick={() => handleEmailClick()} // Pass order data to handleEmailClick
            >
              Leave a Review for Order {}
            </button>
         
        </InformationContainer>
      </div>
    </div>
  );
};

export default Reviews;
