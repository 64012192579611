export const getUserAvailabilityWeek = (workInProgress, hoursAvailable) => {
    // Calculate weeks of work
    const weeksOfWork = workInProgress / hoursAvailable;
  
    // Get the current date
    const currentDate = new Date();
  
    // Get the current week number
    const getCurrentWeekNumber = (date) => {
      const startOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - startOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };
  
    const currentWeekNumber = getCurrentWeekNumber(currentDate);
  
    // Calculate the available week number
    const availableWeekNumber = currentWeekNumber + Math.ceil(weeksOfWork);
  
    return availableWeekNumber;
  };

  export const getCurrentWeekNumber = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  