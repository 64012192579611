// screens/CartScreen.js
import React, { useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import { addToCart, removeFromCart } from "../actions/cartActions";
import CartTable from "../components/CartTable";

const CartScreen = () => {
  const { id: productId } = useParams();
  const location = useLocation();
  const qty = Number(new URLSearchParams(location.search).get("qty"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  console.log(cart);
  const { cartItems } = cart;

  const { userInfo } = useSelector((state) => state.userLogin) ?? {};
  const totalPrice = cartItems
    .reduce((total, item) => total + item.qty * item.price, 0)
    .toFixed(2);
  const vatRate = 0.2; // Example VAT rate (20%)
  const vatPrice = (totalPrice * vatRate).toFixed(2);

  const fontThin = "font-thin";
  const TextTitle = "text-5xl";

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  const removeFromCartHandler = (id, variant) => {
    dispatch(removeFromCart({ id, variant }));
  };

  const checkOutHandler = () => {
    if (!userInfo) {
      navigate("/login?redirect=shipping");
    } else {
      navigate("/shipping");
    }
  };

  return (
    <div className="flex w-full h-custom nav">
      <div className="lg:nav-right w-full lg:w-2/3 h-full relative flex flex-col p-10 overflow-y-auto">
        {cartItems.length === 0 ? (
          <Message>
            Your cart is empty{" "}
            <Link to="/" className="btn w-40 ml-20">
              Go Back
            </Link>
          </Message>
        ) : (
          <CartTable cartItems={cartItems} removeFromCartHandler={removeFromCartHandler} deleteItem={true}/>
        )}
      </div>
      <div className="w-full lg:w-1/3 h-full relative flex flex-col justify-end">
        <div className="w-full h-auto">
          <div className="pt-5 pl-5 pb-2 text-default nav-under">
            <p className={TextTitle}>Order Summary</p>
            <p className="font-thin">total price</p>
            <div className="flex">
              <p className="text-2xl">{`€${totalPrice}`}</p>
              <p
                className={`${fontThin} text-2xl`}
              >{`(inc €${vatPrice} VAT)`}</p>
            </div>
            <p className="font-thin">
              Shipping costs are calculated during checkout
            </p>
          </div>
          <div className="py-5 pr-5 text-default nav-under h-auto flex flex-col justify-end items-end">
            <button
              className="btn w-1/2"
              disabled={cartItems.length === 0}
              onClick={checkOutHandler}
            >
              Ask A Quote!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartScreen;
