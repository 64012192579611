import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  const fontThin = "font-Thin";
  
  const titelText = "titel-text";

  return (
    <nav className="flex justify-between py-4 px-12 nav-under">
      <div>
        {step1 ? (
          <Link to="/login" className={`${titelText} font-normal`}>
            Login
          </Link>
        ) : (
          <span className={`${titelText} cursor-not-allowed`}>Login</span>
        )}
      </div>

      <div>
        {step2 ? (
          <Link to="/shipping" className={`${titelText} font-normal`}>
            Shipping
          </Link>
        ) : (
          <span className={fontThin}>Shipping</span>
        )}
      </div>

      <div>
        {step3 ? (
          <Link to="/payment" className={`${titelText} font-normal`}>
            Payment
          </Link>
        ) : (
          <span className={`${titelText} cursor-not-allowed`}>Payment</span>
        )}
      </div>

      <div>
        {step4 ? (
          <Link to="/placeorder" className={`${titelText} font-normal`}>
            Place Order
          </Link>
        ) : (
          <span className={`${titelText} cursor-not-allowed`}>Place Order</span>
        )}
      </div>
    </nav>
  );
};

export default CheckoutSteps;
