import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getOrderDetails } from "../actions/orderActions";
import { listProductDetails } from "../actions/productActions";
import { getArtistDetails } from "../actions/userActions";
import Loader from "../components/loader";
import Message from "../components/message";

const ProjectScreen = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { id } = useParams(); // Assuming this is the order ID
  const dispatch = useDispatch();

  // Get the order details from the Redux store
  const orderDetails = useSelector((state) => state.orderDetails);
  const { loading: orderLoading, error: orderError, order } = orderDetails;

  // Get the product details from the Redux store
  const productDetails = useSelector((state) => state.productDetails);
  const {
    loading: productLoading,
    error: productError,
    product,
  } = productDetails;

  // get the user details that belong to the product from the Redux store
  const userDetails = useSelector((state) => state.userDetails);
  const { loading: userLoading, error: userError, user } = userDetails;


  useEffect(() => {
    if (id) {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (order && order.orderItems && order.orderItems.length > 0) {
      const firstProduct = order.orderItems[0];
      const productId = firstProduct.product;
      dispatch(listProductDetails(productId)); // Dispatch the action to load product details
    }
  }, [dispatch, order]);

  useEffect(() => {
    if (product) {
      dispatch(getArtistDetails(product.user)); // Dispatch the action to load user details
    }
  }, [dispatch, product]);


  if (orderLoading || productLoading) return <Loader />;
  if (orderError) return <Message variant="danger">{orderError}</Message>;
  if (productError) return <Message variant="danger">{productError}</Message>;

  // Directly destructure projectImages from order
  const { projectImages = {} } = order || {};

  const handleDesignButtonClick = () => {
    if (product) {
      navigate(`/product/${product._id}`); // Navigate to the product page
    }
  };
  
  
  const handleArtistButtonClick = () => {
    if (product) {
      navigate(`/product/${product._id}`); // Navigate to the product page
    }
  };

  const ImageWithFallback = ({ src, alt, ...props }) => {
    const handleError = (e) => {
      e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
      e.target.src = "/path/to/fallback/image"; // Provide a fallback image URL here
    };

    return <img src={src} alt={alt} onError={handleError} {...props} />;
  };

  return (
    <div className="nav flex w-full h-full">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 w-full h-full ">
        <div className="nav-bottom flex flex-col justify-left items-top p-5">
          <p className="text-default text-6xl">Project</p>
          <br/>
          <p className="text-default text-xl font-thin">
          {product.name}
          </p>
        </div>
        <div className="nav-bottom justify-left items-top p-5  text-2xl">
          <p className="text-default font-bold">Location: </p>{" "}
          <p className="text-default font-thin">Zuid Holland</p>
          <br/>
          <p className="text-default font-bold">Date: </p>{" "}
          <p className="text-default font-thin">March 2024</p>
        </div>
      </div>

      <div className="flex w-full justify-start flex-col lg:flex-row">
        <div
          className="blok1 nav-bottom h-[40rem] w-full lg:w-2/3"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/AProjectFrontImage.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Blue square */}
        <div
          className="blok2 nav-bottom lg:nav-left h-[40rem] w-full lg:w-1/3"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/closeUpA.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Red square */}
      </div>
   
      <div className="flex w-full justify-between">
        <div
          className="blok1  nav-standard h-[40rem] w-1/2 lg:w-1/3"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/closeUpB.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Blue square */}
        <div
          className="blok2 nav-bottom lg-nav-left h-[40rem] w-1/2 lg:w-2/3"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/frontView.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Red square */}
      </div>
     
      <div className="flex w-full justify-start flex-col lg:flex-row">
        <div
          className="blok1 nav-bottom  h-[40rem] w-full lg:w-1/2"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/artistWithProject.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Blue square */}
        <div
          className="blok2 nav-bottom lg:nav-left h-[40rem] w-full lg:w-1/2"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/side-view.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Red square */}
      </div>

      <div className="flex w-full justify-between">
        <div
          className="blok1 nav-bottom  h-[40rem] w-1/2  lg:w-1/3"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/isoHigh.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Blue square */}
        <div
          className="blok2 nav-bottom nav-left h-[40rem] w-1/2  lg:w-2/3"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/isoLow.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Red square */}
      </div>

      <div className="flex w-full justify-start flex-col lg:flex-row">
        <div
          className="blok1 nav-bottom h-[40rem] w-full lg:w-1/2"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/lifestyleB.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Blue square */}
        <div
          className="blok2 nav-bottom lg:nav-left h-[40rem] w-full lg:w-1/2"
          style={{
            backgroundImage: `url('/images/projectImages/${id}/lifestyleA.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>{" "}
        {/* Red square */}
      </div>

      <div className="flex w-full justify-between">
        <div className="flex w-full justify-between flex-col lg:flex-row">
          {/* beginning make it yours */}
          <div className=" lg:w-1/2 grid grid-col-2 relative h-full w-full">
            {/* Left Block */}
            <div className="nav-bottom  absolute top-0 left-0 w-full h-full flex flex-col justify-left items-top p-5">
              <p className="text-default text-6xl ">Make it yours</p>
              <br/>
              <p className="text-default text-xl font-thin">
                Design your dream, tailored to your needs
              </p>
              <p className="text-default text-6xl"></p>
            </div>

   
            <div
              className=" absolute bottom-0 left-0 w-full h-1/2 "
              style={{
                //backgroundImage: `url(${product && product.image})`,
                backgroundImage: `url(/images/productImages/${product._id}/F6.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              
              {/* Button with link */}
              <button
                className="btn w-40 m-10 bottom-0"
                onClick={handleDesignButtonClick}
              >
                design it yourself
              </button>
            </div>
            {/* end  make it yours */}
          </div>
          
          {/* Blue square */}
          {/* beginning make it yours */}
          <div className="lg:nav-left w-1/2 relative h-[40rem]">
            {/* Left Block */}
            <div className="nav-standard absolute top-0 left-0 w-full h-1/3 flex flex-col justify-left items-top p-5">
              <p className="text-default text-6xl">The Artist</p>
              <p className="text-default text-xl font-thin">
                {/* link to the user that belongs to the product */}
                {product && product.artist}
              </p>
              <p className="text-default text-6xl"></p>
            </div>

            <div
              className="nav-standard absolute bottom-0 left-0 w-full h-2/3 flex justify-end items-start"
              style={{
                backgroundImage: `url(${projectImages.artistWithProject})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* Button with link */}
              <button
                className="btn  w-40 m-10"
                onClick={handleArtistButtonClick}
              >
                see the artist
              </button>
            </div>
            {/* end  make it yours */}
          </div>
          {/* replace this square with the content */}
        </div>
      </div>
    </div>
  );
};

export default ProjectScreen;
