import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  Window,
  MessageInput,
  MessageList,
  Thread,
  LoadingIndicator,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import CustomChannelHeader from './ChatBoxComponents/CustomChannelHeader';
import chatService from '../services/chatService';
import { useOrder } from './Context/OrderContext'; // Make sure the path is correct

export default function ChatScreen() {
  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);

  const { user: userDetails } = useSelector((state) => state.userDetails);
  const { orderId } = useOrder(); // Destructure orderId from useOrder

  useEffect(() => {
    async function init() {
      if (!userDetails || !userDetails._id) {
        console.error('User details not found');
        return;
      }

      let apiKey;
      try {
        const response = await fetch('/api/config/stream');
        const data = await response.json();
        apiKey = data.apiKey;
      } catch (error) {
        console.error('Failed to fetch API Key:', error);
        return;
      }

      const userToken = await chatService.createChatToken(userDetails._id);
      const chatClient = StreamChat.getInstance(apiKey);

      await chatClient.connectUser({
        id: userDetails._id,
        name: userDetails.name,
        image: `https://getstream.io/random_png/?id=${userDetails._id}&name=${userDetails.name}`,
      }, userToken);

      setClient(chatClient);
    }

    if (userDetails && userDetails._id) {
      init();
    }

    return () => client?.disconnectUser();
  }, [userDetails]);


  useEffect(() => {
    // Function to create or update the chat channel based on the orderId
    const createOrUpdateChannel = async () => {
      if (client && orderId) {
        const channelName = `order-${orderId}`;
        const newChannel = client.channel('messaging', channelName, {
          name: `Order ${orderId} Discussion`,
          members: [userDetails._id, ],
          // Additional channel data can go here
        });

        await newChannel.watch();
        setChannel(newChannel);
      }
    };

    createOrUpdateChannel();
  }, [client, orderId, userDetails._id]); // React to changes in client, orderId, or user ID

  if (!channel || !client) return <LoadingIndicator />;

  return (
    <Chat client={client} theme='str-chat__theme-light'>
      <Channel channel={channel}>
        <Window>
          <CustomChannelHeader />
          <MessageList />
          <MessageInput />
          <h1>
          {orderId}
          </h1>
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};