import React, { useState } from 'react';

import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
  const [isHoveredA, setIsHoveredA] = useState(false);

  const handleMouseEnterA = () => {
    setIsHoveredA(true);
  };

  const handleMouseLeaveA = () => {
    setIsHoveredA(false);
  };

  // Check if ItemMainImage exists in the images array
  const mainImage = product.images.find(image => image.ItemMainImage);
  const ImageSituation = product.images.find(image => image.itemMainImageSituation);


  return (
    <Link to={`/product/${product._id}`}>
      <div
        className="nav-standard flex flex-col justify-between h-[30rem] cursor-pointer text-default relative transition-opacity p-10"
        style={{
        backgroundImage: `url('/images/productImages/${product._id}/ItemMainImage.png')`,
          backgroundSize: '180%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          
          


        }}
        onMouseEnter={handleMouseEnterA}
        onMouseLeave={handleMouseLeaveA}
      >

        <div className="flex justify-center ">
          <p className="text-4xl pt-3">{product.name}</p>
        </div>

        <div className="p-3 flex justify-between mt-auto">
         
          <div className="text-default ml-auto">From: ${product.price.toFixed(0)}</div>
        </div>

        {/* Overlay content */}
        {isHoveredA && (
          <>
            <div className="absolute inset-0 bg-black opacity-70 transition-opacity" />
            <div className="absolute inset-0 flex items-end justify-start transition-opacity">
              
              
                <div
                  className="absolute inset-0 transition-opacity"
                  style={{
                    backgroundImage: `url('/images/productImages/${product._id}/itemMainImageSituation.png')`, // Change the URL here
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default ProductCard;
