// constants.js
// chatConstants.js

export const CHAT_TOKEN_REQUEST = 'CHAT_TOKEN_REQUEST';
export const CHAT_TOKEN_SUCCESS = 'CHAT_TOKEN_SUCCESS';
export const CHAT_TOKEN_FAILURE = 'CHAT_TOKEN_FAILURE';
export const CHAT_LOGOUT = 'CHAT_LOGOUT';

// Add these lines to your existing constants.js file

// Chat-related API endpoints
export const API_BASE_URL = process.env.NODE_ENV === 'development'
  ? `http://localhost:${process.env.PORT || 5000}`
  : 'your_production_url';

export const API_CHAT_TOKEN_ENDPOINT = `${API_BASE_URL}/api/chat/token`;
