// Import the getUserAvailabilityWeek function
import {
    getUserAvailabilityWeek,
    getCurrentWeekNumber,
  } from "./Calculators/getUserAvailabilityWeek";
  
  import React, { useState, useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { getUserDetails, updateUserProfile } from "../actions/userActions";
  import Message from "./message";
  import Loader from "./loader";
  import InformationContainer from "../components/InformationContainer";
  import ToggleComponent from "../components/ToggleComponent";
  import { Link, useNavigate } from "react-router-dom";
  
  const Availability = () => {
    const inputStyle = "py-2 px-3 w-1/2 border-black dropdown w-full";
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const userDetails = useSelector((state) => state.userDetails);
    const { loading, error, user } = userDetails;
  
    const [hoursAvailable, setHoursAvailable] = useState(0);
    const [hoursAvailableFrom, setHoursAvailableFrom] = useState(null);
    const [unavailable, setUnavailable] = useState(false);
  
    useEffect(() => {
      if (!user.name) {
        dispatch(getUserDetails("profile"));
      } else {
        setHoursAvailable(user.hoursAvailable || 0);
        setHoursAvailableFrom(user.hoursAvailableFrom || null);
        setUnavailable(user.unavailable || false);
      }
    }, [dispatch, user]);
  
    const submitHandler = (e) => {
      e.preventDefault();
      const updatedProfile = {
        id: user._id,
        hoursAvailable,
        hoursAvailableFrom,
        unavailable,
      };
      dispatch(updateUserProfile(updatedProfile));
      dispatch(getUserDetails("profile"));
  
      navigate(user.isArtist ? "/artists/dashboard" : "/profile");
    };
  
    // Call the getUserAvailabilityWeek function
    const availableWeekNumber = getUserAvailabilityWeek(
      user.workInProgress,
      hoursAvailable
    );
  
    const currentDate = new Date();
    const currentWeekNumber = getCurrentWeekNumber(currentDate);
  
    return (
      <div className="container p-10">
        <div className="flex flex-row">
          <InformationContainer className="w-full bg-red-200 text-default">
            {user.isArtist && (
              <div className="flex w-full">
                <Link
                  to="/artists/dashboard"
                  className="btn w-40 mr-auto mb-10 text-default"
                >
                  Go Back
                </Link>
              </div>
            )}
  
            {!user.isArtist && (
              <div className="flex w-full">
                <Link
                  to="/profile"
                  className="btn w-40 mr-auto mb-10 text-default"
                >
                  Go Back
                </Link>
              </div>
            )}
  
            <h1 className="text-xl font-bold mb-4 text-default">Edit Profile</h1>
            {error && <Message variant="danger">{error}</Message>}
            {loading && <Loader />}
            <br />
  
            <form onSubmit={submitHandler} className="w-full">
              <div className="flex flex-col w-1/2">
                <label htmlFor="hoursAvailable" className="text-default">
                  Hours Available / Week
                </label>
                <input
                  type="number"
                  id="hoursAvailable"
                  className={inputStyle}
                  value={hoursAvailable}
                  onChange={(e) => setHoursAvailable(parseInt(e.target.value))}
                />
              </div>
              <br />
              <div className="flex flex-col nav p-5 ">
                <div className="flex flex-row">
                  <label htmlFor="hoursAvailableFrom" className="text-default">
                    Work In Process
                  </label>
                  <p className="text-default ml-auto "> {user.workInProgress} Hours</p>
                </div>
                <br />
                {/* Display the calculated available week number */}
                <div className="flex flex-row">
                  <p className="text-default">
                    Current week: 
                  </p>
                  <p className="text-default font-thin ml-auto">
                    Week: {currentWeekNumber}
                  </p>
                  </div>
                  <div className="flex flex-row">
                  <p className="text-default ">
                    Available Week: 
                  </p>
                  <p className="text-default ml-auto font-thin">
                    Week {availableWeekNumber}
                  </p>
                </div>
              </div>
              <br />
              <div className="flex flex-col">
                <label className="text-default">Unavailable</label>
                <p>
                  Please note that setting yourself unavailable will remove you
                  from the search results.
                </p>
                <ToggleComponent
                  name="unavailable"
                  isChecked={unavailable}
                  onChange={(checked) => setUnavailable(checked)}
                />
              </div>
              <button type="submit" className="btn w-40 mt-4 text-default">
                Update
              </button>
            </form>
          </InformationContainer>
        </div>
      </div>
    );
  };
  
  export default Availability;
  