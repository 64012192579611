import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArtistDetails } from "../actions/userActions";

const useArtistDetails = (artistId) => {
  const dispatch = useDispatch();

  // Fetch artist details from Redux store
  const artistDetails = useSelector((state) => state.artistDetails);
  const { loading, error, artist } = artistDetails;

  useEffect(() => {
    // Dispatch action to fetch artist details when the component mounts
    dispatch(getArtistDetails(artistId));
  }, [dispatch, artistId]);

  return { loading, error, artist };
};

export default useArtistDetails;
