import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/profileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/placeOrderScreen";
import OrderScreen from "./screens/orderScreens";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import TestComponent from "./screens/testen";
import UserMessages from "./screens/UserMessages";
import { OrderProvider } from "./components/Context/OrderContext";
import ShopScreen from "./screens/ShopScreen.js";
import ProjectScreen from "./screens/ProjectScreen";
import ProjectListScreen from "./screens/ProjectListScreen";
import ArtistScreen from "./screens/ArtistScreen";
import ArtistsScreen from "./screens/ArtistsScreen";
import OrderScreenTest from "./screens/orderScreenTest";
import AboutUs from "./screens/AboutUs.js";
import BecomeASeller from "./screens/BecomeASeller.js";
import Joinscreen from "./screens/JoinScreen.js";
import DashboardScreen from "./screens/DashboardScreen.js";
import ProductTestScreen from "./screens/ProductTestScreen.js";

function App() {
  
 

  return (
    <Router>
      <div className="flex flex-col min-h-screen ">
        {/* <Header /> */}
        <div className="h-24">
          <Header />
        </div>
      {/* <body> */}
      <main className="flex flex-1">
          <div className="w-full lg:container mx-auto">
            <OrderProvider>
            <Routes>
                {/* //login and account*/}

                <Route path="/login" element={<LoginScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/profile/:id" element={<ProfileScreen />} exact />

                <Route path="/testen" element={<TestComponent />} />
                <Route path="/messages" element={<UserMessages />} />

                {/* // artist */}
                <Route path="/artists" element={<ArtistsScreen />} exact />
                <Route path="/artists/dashboard" element={<DashboardScreen />} exact />
                <Route path="/artists/dashboard/:id" element={<DashboardScreen />} exact />

                <Route path="/artists" element={<ArtistsScreen />} exact />
                <Route path="/artist/:id" element={<ArtistScreen />} exact />

                {/* // projects */}
                <Route path="/projects" element={<ProjectListScreen />} />
                <Route path="/project/:id" element={<ProjectScreen />} exact />

                <Route path="/products" element={<ShopScreen />} />
                <Route path="/product/:id" element={<ProductScreen />} />
                <Route path="/producttest/:id" element={<ProductTestScreen />} />
                <Route path="/cart/" element={<CartScreen />} />
                <Route path="/cart/:id?" element={<CartScreen />} />
                <Route path="/" element={<HomeScreen />} exact />
                <Route
                  path="/page/:pageNumber"
                  element={<HomeScreen />}
                  exact
                />
                <Route path="/search/:keyword" element={<HomeScreen />} exact />
                <Route
                  path="/search/:keyword/page/:pageNumber"
                  element={<HomeScreen />}
                  exact
                />

                <Route path="/shipping" element={<ShippingScreen />} />
                <Route path="/payment" element={<PaymentScreen />} />
                <Route path="/placeorder" element={<PlaceOrderScreen />} />
                <Route path="/order/:id" element={<OrderScreen />} />
                <Route path="/test/:id" element={<OrderScreenTest />} />
                <Route path="/admin/userlist" element={<UserListScreen />} />
                <Route
                  path="/admin/productlist"
                  element={<ProductListScreen />}
                  exact
                />
                <Route
                  path="/admin/productlist/:pageNumber"
                  element={<ProductListScreen />}
                  exact
                />

                <Route path="/admin/orderlist" element={<OrderListScreen />} />
                <Route
                  path="/admin/product/:id/edit"
                  element={<ProductEditScreen />}
                />
                <Route
                  path="/admin/user/:id/edit"
                  element={<UserEditScreen />}
                />

                {/* // all the links from the footer */}
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/becomeaseller" element={<BecomeASeller />} />
                <Route path="/join" element={<Joinscreen />} />
                
              </Routes>
            </OrderProvider>
          </div>
        </main>
      </div>
      <div className="">
          <Footer />
        </div>
    </Router>
  );
}

export default App;
