import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/message';
import Loader from '../components/loader';
import { login } from '../actions/userActions';
import FormContainer from '../components/FormContainer';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate('/'); // Redirect to home page after login
    }
  }, [navigate, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault(); // Prevent default form submission
    dispatch(login(email, password)); // Dispatch the login action
  };

  return (
    <FormContainer> {/* Wrap content with FormContainer */}
     
          <h1 className="text-h1 font-bold mb-8">Sign In</h1>
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <form onSubmit={submitHandler} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-h2 font-medium">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 mt-2 bg-transparent border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            
            <div>
              <label htmlFor="password" className="block text-h2 font-medium">
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 mt-2 bg-transparent border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            
            <button
              type="submit"
              className="w-full font-medium btn"
            >
              Sign In
            </button>
          </form>
          
          <div className="mt-6 text-center">
            <p>
              New Customer? <Link to="/register" className="text-blue-400 hover:text-blue-500">Register</Link>
            </p>
          </div>
        
    </FormContainer>
  );
};

export default LoginScreen;