//userConstants
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCES = 'USER_LOGIN_SUCCES'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCES = 'USER_REGISTER_SUCCES'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCES = 'USER_DETAILS_SUCCES'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'


export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCES = 'USER_UPDATE_PROFILE_SUCCES';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCES = 'USER_LIST_SUCCES'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCES = 'USER_DELETE_SUCCES'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCES = 'USER_UPDATE_SUCCES'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT';

export const ARTIST_DETAILS_REQUEST = 'ARTIST_DETAILS_REQUEST'
export const ARTIST_DETAILS_SUCCES = 'ARTIST_DETAILS_SUCCES'
export const ARTIST_DETAILS_FAIL = 'ARTIST_DETAILS_FAIL'
export const ARTIST_DETAILS_RESET = 'ARTIST_DETAILS_RESET'

export const ARTIST_LIST_REQUEST = 'ARTIST_LIST_REQUEST'
export const ARTIST_LIST_SUCCES = 'ARTIST_LIST_SUCCES'
export const ARTIST_LIST_FAIL = 'ARTIST_LIST_FAIL'
export const ARTIST_LIST_RESET = 'ARTIST_LIST_RESET'