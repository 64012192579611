import React from "react";
import ProfileContainer from "../components/ProfileContainer";
import ProfileGridContainer from "../components/ProfileGridContainer";
import MyOrders from "../components/MyOrders";
import EditProfile from "../components/EditProfile"; // Import EditProfile component
import ChangeEmail from "../components/ChangeEmail"; // Import ChangeEmail component
import DigitalInvoice from "../components/DigitalInvoice"; // Import DigitalInvoice component
import MarketingPreferences from "../components/MarketingPreferences"; // Import MarketingPreferences component
import Reviews from "../components/Reviews"; // Import Reviews component
import DeleteAccount from "../components/DeleteAccount";

import { useParams } from "react-router-dom";

const ProfileScreen = () => {
  const { id } = useParams();
  //log the URL parameter
  console.log(id);

  let childAComponent;

  // Conditionally set childAComponent based on the URL parameter
  switch (id) {
    case "edit":
      childAComponent = <EditProfile />;
      break;

    case "change-email":
      childAComponent = <ChangeEmail />;
      break;

    case "digital-invoice":
      childAComponent = <DigitalInvoice />;
      break;
    
    case "marketing-preferences":
      childAComponent = <MarketingPreferences />;
      break;
    
      case "reviews":
      childAComponent = <Reviews />;
      break;
      
      case "delete":
      childAComponent = <DeleteAccount />;
      break;

    default:
      childAComponent = <ProfileGridContainer />;
      break;
  }

  return (
    <div className="">
      <ProfileContainer childA={childAComponent} childB={<MyOrders />} />
    </div>
  );
};

export default ProfileScreen;
