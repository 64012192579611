import React from "react";
import { Link } from "react-router-dom";
import ArtistScreenList from "../components/ArtistScreenList";


const ArtistsScreen = () => {
  return (
    <div>
    <div className="flex w-full justify-start h-[15rem]">
      <div className="blok1 nav-left nav-standard w-2/3 p-5 relative">
        {/* Content for the left column */}
        <p className="text-default text-6xl">All artist, represented by us</p>

        {/* Buttons placed at the bottom */}
        <div className="absolute bottom-0 left-0 right-0 p-5 flex justify-center gap-5">
          <Link to="/projects" className="btn w-1/2">
            See my projects
          </Link>
          <Link to="/products" className="btn w-1/2">
            Check the products
          </Link>
        </div>
      </div>
      <div className="nav-standard justify-left items-top p-5">
        <p className="text-default text-2xl">Our 2 artists,</p>
        <p className="text-default font-thin text-2xl">
          All with different items and designs
        </p>
      </div>
      
      
    </div>
    <div className="">  {/* Added margin-top for spacing */}
        <ArtistScreenList />
      </div>
    </div>
    
  );
};

export default ArtistsScreen;
