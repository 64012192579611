import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../actions/cartActions";
import FormContainer from "../components/FormContainer";

const PaymentScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const TextTitle = "text-5xl";
  const containerClass = "py-2 text-default font-bold";
  const inputStyles = "py-2 px-3 w-full nav dropdown";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!shippingAddress) {
    navigate("/shipping");
  }

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate("/placeorder");
  };

  return (
    <FormContainer>
      <form onSubmit={submitHandler} className="">
        <CheckoutSteps step1 step2 step3 />
        <div className="p-5">
          <p className={TextTitle}>Payment</p>
          <Form.Group>
            <p className={containerClass}>Select Payment Method</p>
            <>
              <label className="font-thin py-2">
                <input
                  type="radio"
                  className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  id="PayPal"
                  name="paymentMethod"
                  value="PayPal"
                  checked={paymentMethod === "PayPal"}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <span className="pl-2">PayPal or Credit Card</span>
              </label>
            </>
          </Form.Group>
          <div className="flex justify-end">
            <button type="submit" className="btn w-40 ml-auto mt-10">
              Continue
            </button>
          </div>
        </div>
      </form>
    </FormContainer>
  );
};

export default PaymentScreen;
