import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import Message from './message';
import Loader from './loader';
import InformationContainer from '../components/InformationContainer';
import { Link, useNavigate } from 'react-router-dom';

const ProfileScreen = () => {
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [artistWebsite, setArtistWebsite] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [kvkNumber, setKvkNumber] = useState('');
  const inputStyle = "py-2 px-3 w-1/2 border-black dropdown w-full";
  const dispatch = useDispatch();
  
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    if (!user.name) {
      dispatch(getUserDetails('profile'));
    } else {
      setName(user.name);
      setStreet(user.street || '');
      setStreetNumber(user.streetNumber || '');
      setCity(user.city || '');
      setPostalCode(user.postalCode || '');
      setArtistWebsite(user.artistWebsite || '');
      setBusinessName(user.businessName || '');
      setVatNumber(user.vatNumber || '');
      setKvkNumber(user.kvkNumber || '');
    }
  }, [dispatch, user]);

  const submitHandler = (e) => {
    e.preventDefault();
    const updatedProfile = {
      id: user._id,
      // Only include fields that are filled in
      ...(name && { name }),
      ...(street && { street }),
      ...(streetNumber && { streetNumber }),
      ...(city && { city }),
      ...(postalCode && { postalCode }),
      ...(artistWebsite && { artistWebsite }),
      ...(businessName && { businessName }),
      ...(vatNumber && { vatNumber }),
      ...(kvkNumber && { kvkNumber }),
    };
    dispatch(updateUserProfile(updatedProfile));
    dispatch(getUserDetails('profile'));
  
    // Navigate based on user type after updating profile
    navigate(user.isArtist ? '/artists/dashboard' : '/profile');
  
  // Remove reload - not recommended after form submission
  // window.location.reload();
  };
  

  return (
    <div className="container p-10 ">
      <div className="flex flex-row">
        
        <InformationContainer className="w-full bg-red-200 text-default">
          
          {user.isArtist && (
            <div className='flex w-full'>
            <Link to='/artists/dashboard' type="submit" className="btn w-40 mr-auto mb-10  text-default">
                Go Back
              </Link>
          </div>
          )}
          
          {!user.isArtist && (


        <div className='flex w-full'>
          <Link to='/profile' type="submit" className="btn w-40 mr-auto mb-10  text-default">
              Go Back
            </Link>
        </div>

          )}

          <h1 className="text-xl font-bold mb-4 text-default">Edit Profile</h1>
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}

          

          <form onSubmit={submitHandler} className='w-1/3'>
            <div className="w-full">
              <label className="block text-default my-2" htmlFor="name">
                Name
              </label>
              <input
                //create class with inputStyle
                className={inputStyle}
                type="text"
                id="name"
                placeholder={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="street">
                Street
              </label>
              <input
                className={inputStyle}
                type="text"
                id="street"
                placeholder={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="streetNumber">
                Street Number
              </label>
              <input
               className={inputStyle}
                type="text"
                id={streetNumber}
                placeholder={streetNumber}
                onChange={(e) => setStreetNumber(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="city">
                City
              </label>
              <input
               className={inputStyle}
                type="text"
                id="city"
                placeholder={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="postalCode">
                Postal Code
              </label>
              <input
                className={inputStyle}
                type="text"
                id="postalCode"
                placeholder={postalCode}
                
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
           
          {user.isArtist && (
            <>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="artistWebsite">
                Artist Website
              </label>
              <input
                className={inputStyle}
                type="text"
                id="artistWebsite"
                placeholder={artistWebsite}
                onChange={(e) => setArtistWebsite(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="businessName">
                Business Name
              </label>
              <input
                className={inputStyle}
                type="text"
                id="businessName"
                placeholder={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="vatNumber">
                VAT Number
              </label>
              <input
                className={inputStyle}
                type="text"
                id="vatNumber"
                placeholder={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="kvkNumber">
                KVK Number
              </label>
              <input
                className={inputStyle}
                type="text"
                id="kvkNumber"
                placeholder={kvkNumber}
                onChange={(e) => setKvkNumber(e.target.value)}
              />
            </div>

            </>
          )}

            

          
            <div className='flex w-full'>
        
        <button type="submit" className="btn w-40 ml-auto ">
              Update
            
        </button>
          </div>

          </form>
          
          

        </InformationContainer>
      </div>
    </div>
  );
};

export default ProfileScreen;
