import React from "react";
import ProductScreenDrag from "../components/ProductScreenDrag";
import { Link } from "react-router-dom";

const repeatedDiv = (startIndex) => {
  return Array.from({ length: 3 }).map((_, index) => (
    <div
      key={index}
      className="w-full flex justify-center items-center "
      style={{
        backgroundImage: `url(/images/Screens/BecomeASeller/F${
          startIndex + index
        }.png)`,
        backgroundSize: "300%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></div>
  ));
};

const AboutUs = () => {
  return (
    <div className="w-full nav-standard nav-left nav-top pt-20">
      <div>
        <div className="flex justify-center pb-20">
          <p className="text-default text-6xl text-center w-2/3">
            Why artist love to be part of De Knal.
          </p>
        </div>

        <div className="flex text-center text-default text-2xl mt-8 flex-col w-2/3 mx-auto">
          When a customer places an order with De Knal, we ensure that the
          artist has everything needed to make the project a success.
          <br />
          <br />
          See why you should join us!
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-6 md:grid-rows-3 pt-20 nav-bottom">
        {/* red container with half width */}

        {/* 1nd container with half width */}
        <div
          className="nav-top na w-full h-full relative flex flex-col justify-center"
          style={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/CompanyImages/logoDeKnalAchtergrond.png)`, // Add linear gradient with 50% opacity over the background image
            backgroundSize: "50%", // Set background size to 50%
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat", // Set background repeat to no-repeat
            position: "relative",
          }}
        >
          {/* Inside the  <ProductScreenDrag id={id} /> ProductScreenDrag component, pass the productId as a prop */}

          <ProductScreenDrag
            id={"66472f86f7e07dcea7f34289"}
            variant="product"
          />

          <div className="absolute top-0 right-0 flex flex-col items-right p-5 gap-2">
            {/* add image located at /Users/michielvandeputte/Library/Mobile Documents/com~apple~CloudDocs/webshopDrie/webshopTwee/frontend/public/images/productImages/6602822587d0d07a214daab7/VariationImages/WidhtCabinet.svg*/}
          </div>

          <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center ">
            <span className="text-default  text-2xl font-thin pr-3">←</span>
            <p className="text-default  p-6 text-2xl font-thin">
              Drag to rotate
            </p>
            <span className="text-default  text-2xl font-thin pl-3">→</span>
          </div>
        </div>

        {/* 2nd container with half width */}
        <div className="w-full flex flex-col justify-end p-10 gap-3 nav-top nav-right">
          <p className="text-default text-4xl">Marketing and Reach</p>

          <p className="text-default text-2xl font-thin w-full xl:w-2/3">
            Join De Knal and gain access to a larger customer base and extensive
            marketing support. <br />
            <br />
          </p>

          <p className="text-default text-4xl">Efficient Production</p>

          <p className="text-default text-2xl font-thin w-full xl:w-2/3">
            De Knal assists you in converting your custom designs into a
            parametric model and integrates it with the website. <br />
            <br />
          </p>

          <p className="text-default text-4xl">Sales and Quotes</p>

          <p className="text-default text-2xl font-thin w-full xl:w-2/3">
            De Knal makes it easy for customers to request live quotes via the
            website, resulting in a quick and transparent sales experience.{" "}
            <br />
            <br />
          </p>
        </div>

        {/* 3nd container with half width */}
        <div className="w-full flex flex-col justify-end p-10 gap-3 nav-standard nav-top">
          <p className="text-default text-6xl mt-40">Clear Information</p>
          <p className="text-default text-4xl">Drawing</p>
          <div>
            <p className="text-default text-2xl font-thin w-full xl:w-2/3">
              Artist receive a detailed drawing of the design.
              <br />
              <br />
              This drawing contains all the specifications and dimensions,
              compiled based on the artist's working method, so there is no
              doubt about what needs to be made, with input coming directly from
              the customer.
            </p>
          </div>
        </div>

        {/* 4nd container with half width */}
        <div className="w-full h-full p-10 nav-top nav-bottom">
          <div className="w-full h-full flex flex-row">
            <div className="w-1/2 h-full  text-default text-xl p-10 flex flex-col items-center justify-center nav">
              <div className="">
                <p className="text-default text-4xl">Example</p>
                <br />
                <p>
                  Drawing: <span class="font-thin">Cabinet</span>
                </p>
                <p>
                  Door Combination:{" "}
                  <span class="font-thin">Wood; Wood; Glass</span>
                </p>
                <br />
              </div>
              <div>
                <p>
                  Dept: <span class="font-thin">40</span>
                </p>
                <p>
                  Height: <span class="font-thin">200</span>
                </p>
                <p>
                  Width: <span class="font-thin">150</span>
                </p>
                <p>
                  Profile: <span class="font-thin">4 X 4</span>
                </p>
                <p>
                  Edge Softness: <span class="font-thin">5</span>
                </p>
                <p>
                  Material: <span class="font-thin">Wood (Birch)</span>
                </p>
                <p>
                  Labour Hours: <span class="font-thin">Approx 10H</span>
                </p>
                <p>
                  Cost Material: <span class="font-thin">€240.00</span>
                </p>
              </div>
            </div>
            <div
              className="w-1/2 flex justify-center items-center  nav-standard nav-top"
              style={{
                backgroundImage: `url(/images/Screens/BecomeASeller/TechnicalDrawing.png)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        {/* 5nd container with half width */}
        <div
          className="w-full flex nav-right flex-col"
          style={{
            backgroundImage: `url(/images/Screens/BecomeASeller/logoDeKnalRainbowBackNoLogo.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full h-1/2">
            <div class="relative w-full h-full">
              <div className="h-1/3 w-full bg-white"></div>
              <div className="h-1/6 w-full flex flex-row">
                <div className="h-full pl-10 bg-white"></div>
                <div className="h-full flex-grow"></div>
                <div className="h-full pl-10 bg-white"></div>
              </div>

              <div className="h-1/3 w-full bg-white"></div>
              <div className="h-1/6 w-full bg-white"></div>
              <div class="absolute m-auto top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 h-2/3 w-full">
                <div className="w-full h-full mx-auto flex flex-row ">
                  {repeatedDiv(1)}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-1/2">
            <div class="relative w-full h-full">
              <div className="h-1/3 w-full bg-white"></div>
              <div className="h-1/6 w-full flex flex-row">
                <div className="h-full pl-10 bg-white"></div>
                <div className="h-full flex-grow"></div>
                <div className="h-full pl-10 bg-white"></div>
              </div>

              <div className="h-1/3 w-full bg-white"></div>
              <div className="h-1/6 w-full bg-white"></div>
              <div class="absolute m-auto top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 h-2/3 w-full">
                <div className="w-full h-full mx-auto flex flex-row ">
                  {repeatedDiv(4)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 6nd container with half width */}
        <div className="w-full flex flex-col justify-end p-10 gap-3 ">
          <p className="text-default text-4xl">Integration of Equipment</p>
          <div>
            <p className="text-default text-2xl font-thin w-full xl:w-2/3">
              We collaborate with the artists to integrate their equipment with
              the information generated by our system. The platform produces 3D
              models, DXF files, and various types of milling files that can be
              used directly.
              <br />
              <br />
              This means that all technical details are as closely aligned as
              possible with the available tools and machines, ensuring efficient
              and accurate production.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center py-20">
        <p className="text-default text-6xl text-center w-2/3 pt-20">
          De Knal!
        </p>
      </div>

      <div className="flex text-center text-default text-2xl flex-col w-2/3 mx-auto pb-20">
        <p>Then sign up today, and we create something beautiful together.</p>

        <div className="btn w-40 mx-auto mt-10">
          <Link to="/join">Join De Knal</Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
