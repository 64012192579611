import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { getUserDetails } from "../actions/userActions";
import { listMyOrders } from "../actions/orderActions";
import { useOrder } from "../components/Context/OrderContext";
import InformationContainer from "../components/InformationContainer";
import {
  getCurrentWeekNumber,
  getUserAvailabilityWeek,
} from "./Calculators/getUserAvailabilityWeek";
import { listProducts } from "../actions/productActions";

const ProfileContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
  } = productList; // Add products to destructuring

  // Inside ProfileScreen component
  const { setOrderId } = useOrder();

  const calculateFutureDate = (days) => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + days);
    return futureDate.toDateString();
  };

  const futureDate = calculateFutureDate(20);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (!user.name) {
        dispatch(getUserDetails("profile"));
        dispatch(listMyOrders());
      }
    }
  }, [dispatch, navigate, userInfo, user.name]);

  // list all the products using

  useEffect(() => {
    dispatch(listProducts({ user: user._id }));
  }, [dispatch, user._id]);

  // Call the getUserAvailabilityWeek function
  const availableWeekNumber = getUserAvailabilityWeek(
    user.workInProgress,
    user.hoursAvailable
  );

  const currentDate = new Date();
  const currentWeekNumber = getCurrentWeekNumber(currentDate);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col text-default text-l">
        <div className="w-full text-6xl mb-5">Dashboard</div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-3 gap-5 w-full">
          <div className="">
            <InformationContainer>
              <div className="font-bold">Artist Profile</div>
              
              {user.isArtist && (
                <>
                 
                  <div className=" font-light">{user.businessName}</div>
                  <br />
                  <div className="">Artist Website</div>
                  <div className="text-sm font-light">
                    <a
                      href={user.artistWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     website
                    </a>
                  </div>
                  <br />
                  <div className="">VAT Number</div>
                  <div className=" font-light">{user.vatNumber}</div>
                  <br />
                  <div className="">KVK Number</div>
                  <div className=" font-light">{user.kvkNumber}</div>
                  <br />
                </>
              )}
              <Link
                to="/artists/dashboard/edit"
                className="w-40 btn ml-auto mt-auto"
              >
                Change
              </Link>
            </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className=" font-bold">Inloggegevens</div>
              <br />
              <div className="">Email</div>
              <div className=" font-light">{user.email}</div>
              <br />
              <div className="">Password</div>
              <div className=" font-light">********</div>
              <Link
                to="/artists/dashboard/change-email"
                className="w-40 btn ml-auto mt-auto"
              >
                Change
              </Link>
            </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className=" font-bold">Availability</div>
              <br />
              {user.unavailable === true ? (
                <div className=" font-light">
                  You are currently unavailable for projects.
                </div>
              ) : (
                <>
                  <div className="">Artist Availability</div>
                  <div className=" font-light">
                    Currently, you are available for {user.hoursAvailable} hours
                    per week.
                  </div>
                  <br />
                </>
              )}
              <div className="flex flex-col items-end mt-auto">
                <div className="flex flex-col nav p-3 w-full">
                  <div className="flex flex-row">
                    <label
                      htmlFor="hoursAvailableFrom"
                      className="text-default"
                    >
                      Work In Process
                    </label>
                    <p className="text-default ml-auto font-thin">
                      {" "}
                      {user.workInProgress} Hours
                    </p>
                  </div>
                  <br />
                  {/* Display the calculated available week number */}
                  <div className="flex flex-row">
                    <p className="text-default">Current week:</p>
                    <p className="text-default font-thin ml-auto">
                      Week: {currentWeekNumber}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="text-default ">Available Week:</p>
                    <p className="text-default ml-auto font-thin">
                      Week {availableWeekNumber}
                    </p>
                  </div>
                </div>

                <br />

                <Link
                  to="/artists/dashboard/availability"
                  className="w-40 btn ml-auto"
                >
                  Change
                </Link>
              </div>
            </InformationContainer>
          </div>

          <div className="">
            <InformationContainer>
              <div className=" font-bold">Rates</div>
              <br />
              <div className=" font-light">
                Below are the rates for your current products. You can review
                and update them as needed.
              </div>
              <br />
              
              <br />

              <br />
              <div className="flex flex-col items-end mt-auto">
              <table className="min-w-full nav-top nav-left">
                <thead className="nav-standard">
                  <tr>
                    <th className="py-2 px-4">Product</th>
                    <th className="py-2 px-4">Rate/H</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <tr key={index} className="nav-standard">
                      <td className="py-2 px-4">{product.name}</td>
                      <td className="py-2 px-4">{product.pricePerHour}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

                <br />

                <Link
                  to="/artists/dashboard/rates"
                  className="w-40 btn ml-auto"
                >
                  Change
                </Link>
              </div>
            </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className=" font-bold">Invoice</div>
              <br />
              <div className=" font-light">
                Your invoice details are provided here.
              </div>
              <br />
              <Link
                to="/artists/dashboard/digital-invoice"
                className="w-40 btn ml-auto mt-auto"
              >
                Change
              </Link>
            </InformationContainer>
          </div>
          
          <div className="">
            <InformationContainer>
              <div className=" font-bold">Artist on De Knal</div>
              <br />
              <div className=" font-light">
                {user.isArtist === true ? (
                  <span>
                    Your profile is visible on De Knal. You can view your
                    profile here.
                  </span>
                ) : (
                  <span>
                    Your profile is not yet visible on De Knal. Your request is
                    pending approval.
                  </span>
                )}
              </div>
              <br />

              {/* Grid container */}
              <div className="grid grid-cols-2 grid-rows-1 gap-2 mb-10">
                {/* Image 1 */}
                <div className="relative">
                  <img
                    className="w-full h-full object-cover"
                    src="/images/artistImages/66028ccb3dd79b903adee710/artistImageProfile.jpeg"
                    alt="Artist 1"
                  />
                </div>
                {/* 2 */}
                <div className="relative">
                  <img
                    className="w-full h-full object-cover"
                    src="/images/artistImages/66028ccb3dd79b903adee710/artistImageWithProductA.jpeg"
                    alt="Artist 2"
                  />
                </div>
                
              </div>

              <Link to="/artist/:id" className="w-40 btn ml-auto mt-auto">
                See Profile
              </Link>
            </InformationContainer>
          </div>

          <div className="">
            <InformationContainer>
              <div className=" font-bold">
                Stop Being Artist on De Knal
              </div>
              <br />
              <div className=" font-light">
                Are you sure you want to stop being an artist on De Knal? This
                action cannot be undone and you will lose your artist profile
                and any associated listings.
              </div>
              <br />
              <Link
                to="/artists/dashboard/dashboard/delete"
                className="w-40 btn ml-auto mt-auto"
              >
                See Profile
              </Link>
            </InformationContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
