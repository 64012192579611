import React from 'react';

const InformationContainer = ({ children }) => {
  return (
    <div className="flex w-full h-full nav p-10 flex-col">
      
        {children}
      
    </div>
  );
};

export default InformationContainer;
