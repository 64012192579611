import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import Message from './message';
import Loader from './loader';
import InformationContainer from '../components/InformationContainer';
import { Link, useNavigate } from 'react-router-dom';

const ProfileScreen = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const inputStyle = "py-2 px-3 w-1/4 border-black dropdown w-full";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;

  useEffect(() => {
    if (!user.email) {
      dispatch(getUserDetails('profile'));
    } else {
      setEmail(user.email || '');
    }
  }, [dispatch, user]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!oldPassword && (newPassword || email)) {
      setError('Please enter your old password.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }

    try {
      await dispatch(updateUserProfile({ oldPassword, newPassword, email }));
      setSuccess('Profile updated successfully!');
    } catch (error) {
      setError('Failed to update profile. Please make sure your old password is correct.');
    }

    dispatch(getUserDetails('profile'));
  
    // Navigate based on user type after updating profile
    navigate(user.isArtist ? '/artists/dashboard' : '/profile');

  };

  return (
    <div className="container p-10">
      <div className="flex flex-row">
        
        <InformationContainer className="w-full bg-red-200 text-default">

          {user.isArtist && (
            <div className='flex w-full'>
            <Link to='/artists/dashboard' type="submit" className="btn w-40 mr-auto mb-10  text-default">
                Go Back
              </Link>
          </div>
          )}
          
          {!user.isArtist && (


        <div className='flex w-full'>
          <Link to='/profile' type="submit" className="btn w-40 mr-auto mb-10  text-default">
              Go Back
            </Link>
        </div>

          )}
          <h1 className="text-xl font-bold mb-4 text-default">Edit Profile</h1>
          {error && <Message variant="danger">{error}</Message>}
          {success && <Message variant="success">{success}</Message>}
          {loading && <Loader />}

          <form onSubmit={submitHandler} className='w-1/3'>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="oldPassword">
                Old Password
              </label>
              <input
                className={inputStyle}
                type="password"
                id="oldPassword"
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="newPassword">
                New Password
              </label>
              <input
                className={inputStyle}
                type="password"
                id="newPassword"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <input
                className={inputStyle}
                type="password"
                id="confirmPassword"
                placeholder="Repeat new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-default my-2" htmlFor="email">
                Email Address
              </label>
              <input
                className={inputStyle}
                type="email"
                id="email"
                placeholder={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
           
            <div className='flex w-full'>
              <button type="submit" className="btn w-40 ml-auto">
                Update Profile
              </button>
            </div>
          </form>
        </InformationContainer>
      </div>
    </div>
  );
};

export default ProfileScreen;
