import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";
import { useParams } from "react-router-dom";
import { listProductDetails } from "../actions/productActions";
import { addToCart } from "../actions/cartActions";
import ProductScreenDrag from "../components/ProductScreenDrag";
import ProductPrice from "../components/Calculators/ProductPrice";
import ProductPriceTest from "../components/Calculators/ProductPriceTest";

const ProductScreen = () => {
  // Define the Tailwind CSS class
  const containerClass = "p-3 text-default nav-top";
  const containerClassTitle = "pt-5 pl-5 pb-2 text-default nav-top";
  const TextTitle = "text-5xl";

  const font = "font-bold";
  const variationStyle =
    "dropdown block py-2 px-3 w-40 ml-auto focus:outline-none focus:ring-black focus:border-black sm:text-sm";
  const inputStyle = "py-2 px-3 w-40 ml-auto border-black dropdown";
  const inputStyle2 = "py-2 px-3 w-40 ml-auto border-black dropdown";

  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [isVariationSelected, setIsVariationSelected] = useState(false);
  const [addToCartCount, setAddToCartCount] = useState(0); // State to count the number of times AddToCartHandler has been called
  // Inside the ProductScreen component
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [squareCount, setSquareCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const { id } = useParams();
  const navigate = useNavigate();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    dispatch(listProductDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    // Run whenever product or selectedVariants change
    console.log(selectedVariants); // Log selectedVariants here
    setIsVariationSelected(
      product &&
        product.variations &&
        product.variations.every(
          (variation) => selectedVariants[variation.name]
        )
    );
  }, [product, selectedVariants]);

  const AddToCartHandler = () => {
    const hasVariants =
      product && product.variations && product.variations.length > 0;

    const variantsToAdd = hasVariants ? selectedVariants : null;
    console.log();

    // Inside the AddToCartHandler function
    dispatch(addToCart(id, qty, variantsToAdd, calculatedPrice, product.user));

    // Increment the count every time AddToCartHandler is called
    setAddToCartCount((prevCount) => prevCount + 1);
  };

  return (
    <div className="flex flex-col lg:flex-row h-full nav mx-auto container overflow-hidden">
        <div className="flex w-full lg:w-8/12 h-[27rem] lg:h-custom">
        <div className="lg:nav-right w-full h-full relative flex justify-center" style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/CompanyImages/logoDeKnalAchtergrond.png)`, // Add linear gradient with 50% opacity over the background image
              backgroundSize: "50%", // Set background size to 50%
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Set background repeat to no-repeat
              position: "relative",
            }}
          >
            {/* Inside the  <ProductScreenDrag id={id} /> ProductScreenDrag component, pass the productId as a prop */}

            <ProductScreenDrag id={id} variant="product" />

            <div className="absolute top-0 left-0 flex flex-col items-left p-3 gap-2">
              <Link to="/products" className="btn w-40 mr-3">
                Go Back
              </Link>
              {errorMessage && <div className="btn w-1/3">{errorMessage}</div>}
            </div>

            <div className="absolute top-0 right-0 flex flex-col items-right p-5 gap-2">
                          {/* add image located at /Users/michielvandeputte/Library/Mobile Documents/com~apple~CloudDocs/webshopDrie/webshopTwee/frontend/public/images/productImages/6602822587d0d07a214daab7/VariationImages/WidhtCabinet.svg*/}
             
              
            </div>


            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center ">
              <span className="text-default  text-2xl font-thin pr-3">←</span>
              <p className="text-default  p-6 text-2xl font-thin">
                Drag to rotate
              </p>
              <span className="text-default  text-2xl font-thin pl-3">→</span>
            </div>
          </div>

            </div>
        <div className="flex w-full lg:w-4/12 h-custom">
        <div className="w-full h-full relative justify-between">
            <div className="w-full h-full overflow-y-auto">
              <div
                className={`flex p-3`}
              >
                <div className="flex flex-rows justify-between w-full" >
                <Link to="/projects" className="btn w-40">
                  See projects
                </Link>
                <Link to={`artist/${product.user}`} className="btn w-40">
                  See artist
                </Link>
                </div>
              </div>

              <div className={`${containerClassTitle}`}>
                <p className={`${TextTitle}`}>{product.name}</p>
              </div>

              <div className={`${containerClass} h-auto`}>
                <p className={`${font} w-1/5`}>About:</p>
                <p className="">{product.description}</p>
              </div>

              {product &&
                product.variations &&
                product.variations.map((variation) => (
                  <div
                    key={variation._id}
                    className={`${containerClass} h-auto flex items-center`}
                  >
                    <div className="flex w-1/2">
                      <p className={`${font}`}>{`${variation.name}`}</p>
                      {/* see if variation is something */}

                      {variation.unit && (
                        <p
                          className={`font-regular text-gray-500 pl-2`}
                        > {` (${variation.unit})`}</p>
                      )}
                    </div>

                    {variation.formula === "Integer" && (
                      <input
                        className={inputStyle}
                        type="number"
                        // If variation.defaultOption exists, set it as the default value
                        placeholder={variation.defaultOption}
                        onChange={(e) => {
                          const value = Math.floor(e.target.value);
                          // Ensure the value is within the specified min and max options
                          const constrainedValue = Math.max(
                            parseInt(variation.minOption, 10),
                            Math.min(parseInt(variation.maxOption, 10), value)
                          );

                          setSelectedVariants({
                            ...selectedVariants,
                            [variation.name]: constrainedValue,
                          });
                        }}
                        onBlur={(e) => {
                          const value = Math.floor(e.target.value);
                          const minOption = parseInt(variation.minOption, 10);
                          const maxOption = parseInt(variation.maxOption, 10);

                          // Ensure the value is within the specified min and max options
                          const constrainedValue = Math.max(
                            minOption,
                            Math.min(maxOption, value)
                          );

                          // Check if the value was adjusted
                          if (value !== constrainedValue) {
                            setErrorMessage(
                              `${variation.name} was adjusted to stay within the allowed range (${minOption}-${maxOption}).`
                            );
                            // Clear the error message after 3 seconds
                            setTimeout(() => {
                              setErrorMessage("");
                            }, 3000);
                          } else {
                            setErrorMessage(""); // Clear the error message if the value is within range
                          }

                          // Update the input field value to reflect the constrained value
                          e.target.value = constrainedValue;

                          setSelectedVariants({
                            ...selectedVariants,
                            [variation.name]: constrainedValue,
                          });
                        }}
                        // Set the min and max attributes for the input field
                        min={variation.minOption}
                        max={variation.maxOption}
                      />
                    )}

                    {variation.formula === "Integer-list" && (
                      <div className="W-full">
                        <input
                          className={`${inputStyle2} w-1/2`}
                          type="number"
                          onChange={(e) => {
                            const count = Math.max(
                              0,
                              Math.floor(e.target.value)
                            );
                            setSelectedVariants({
                              ...selectedVariants,
                              [variation.name]: count, // Update selectedVariants with the count
                            });
                            setSquareCount(count); // Set squareCount state
                          }}
                        />

                        {/* Red squares */}
                        <div className="flex flex-col py-2 gap-2">
                          {Array.from({ length: squareCount }, (_, index) => (
                            <select
                              className={`${inputStyle2} w-1/2 ml-auto`}
                              value={
                                selectedVariants[variation.name + index] || ""
                              } // Use selected value from state or default to an empty string
                              onChange={(e) =>
                                setSelectedVariants({
                                  ...selectedVariants,
                                  [variation.name + index]: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Select {variation.name}
                              </option>
                              {variation.options.map((option) => (
                                <option key={option._id} value={option.option}>
                                  {option.option}
                                </option>
                              ))}
                            </select>
                          ))}
                        </div>
                      </div>
                    )}

                    {variation.formula === "List" && (
                      <select
                        className={variationStyle}
                        value={selectedVariants[variation.name] || ""} // Default to an empty string
                        onChange={(e) =>
                          setSelectedVariants({
                            ...selectedVariants,
                            [variation.name]: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          Select {variation.name}
                        </option>
                        {variation.options.map((option) => (
                          <option key={option._id} value={option.option}>
                            {option.option}
                          </option>
                        ))}
                      </select>
                    )}
                    {variation.formula === "String" && (
                      <input
                        className={inputStyle}
                        type="text"
                        onChange={(e) =>
                          setSelectedVariants({
                            ...selectedVariants,
                            [variation.name]: Math.max(
                              0,
                              Math.floor(e.target.value)
                            ), // Ensure value is at least 0
                          })
                        }
                      />
                    )}
                  </div>
                ))}

              

              <div className={`${containerClass} h-auto mb-12 mt-auto`}>
                <p className={TextTitle}>Order Summary</p>
                <br/>
                <div className="flex">
                  <div className={`${TextTitle} flex`}>
                    <ProductPrice
                      product={product}
                      selectedVariants={selectedVariants}
                      setCalculatedPrice={(price) => setCalculatedPrice(price)}
                    />
                  </div>

                  <p className={`${font} pl-3 mt-auto`}>{`inc eur vat`}</p>
                </div>

                <p className="font-thin">
                  Shipping costs are calculated during next step
                </p>
              </div>

              
            </div>

            <div className="absolute bottom-0 left-0 p-2  bg-white  w-full flex justify-between items-center nav-top">
              <button
                onClick={AddToCartHandler}
                className="btn w-40 "
                disabled={!isVariationSelected || addToCartCount >= 5}
              >
                {addToCartCount >= 5 ? "Max saved" : `Save (${addToCartCount})`}
              </button>

              <Link
                to="/cart"
                className="btn w-40"
                disabled={!isVariationSelected && addToCartCount >= 1}
              >
                Overview
              </Link>
            </div>
          </div>
            </div>
          
    </div>
  );
};

export default ProductScreen;
