import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../screens/Card';
import Message from './message';
import Loader from './loader';
import { listProducts } from '../actions/productActions';

const ProductsList = ({ numberOfCards }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  // Slice the products array based on the specified number of cards
  const slicedProducts = products.slice(0, numberOfCards);

  
  return (
    <div className="flex-grow flex flex-col">
      
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="grid grid-cols-2 grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 nav-left">
            {slicedProducts.map((product) => (
              <Card key={product._id} product={product} />
            ))}
          </div>
        )}
      
    </div>
  );
};

export default ProductsList;
