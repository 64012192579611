import React from "react";

const ProductPrice = ({ product, selectedVariants, setCalculatedPrice }) => {
  let totalPrice = product.price || 0;

  switch (product._id) {
    case "66017ac0b4c7adb1b46c02b8":
  const { HeightBench = 0, WidthBench = 0, DepthBench = 0, Finish } = selectedVariants;

  // Calculate base prices for HeightBench, WidthBench, and DepthBench
  totalPrice += HeightBench * product.variations.find(variation => variation.name === "HeightBench").options[0].additionalPrice;
  totalPrice += WidthBench * product.variations.find(variation => variation.name === "WidthBench").options[0].additionalPrice;
  totalPrice += DepthBench * product.variations.find(variation => variation.name === "DepthBench").options[0].additionalPrice;
  
  // Adjust price based on Finish
  if (Finish) {
    const matchingFinishOption = product.variations.find(variation => variation.name === "Finish").options.find(opt => opt.option === Finish);
    if (matchingFinishOption) {
      totalPrice *= matchingFinishOption.additionalPrice;
    }
  }

  break;
    case "66472f86f7e07dcea7f34289":
  const { Height = 0, Width = 0, Depth = 0, FinishTable } = selectedVariants;

  // Calculate base prices for Height, Width, and Depth
  totalPrice += Height * product.variations.find(variation => variation.name === "Height").options[0].additionalPrice;
  totalPrice += Width * product.variations.find(variation => variation.name === "Width").options[0].additionalPrice;
  totalPrice += Depth * product.variations.find(variation => variation.name === "Depth").options[0].additionalPrice;
  
  // Adjust price based on Finish
  if (FinishTable) {
    const matchingFinishOption = product.variations.find(variation => variation.name === "FinishTable").options.find(opt => opt.option === FinishTable);
    if (matchingFinishOption) {
      totalPrice *= matchingFinishOption.additionalPrice;
    }
  }

  break;


      case "6602822587d0d07a214daab7":
      // Add your own logic here
      totalPrice += 100; // For example, increase the total price by 100
      // Destructure selected variants
      const { HeightCabinet = 0, DepthCabinet = 0, WidthCabinet = 0, ProfileHeight, NumberDoors } = selectedVariants;
      // Add the additional price for the selected variants
      totalPrice += HeightCabinet * product.variations[0].options[0].additionalPrice;
      totalPrice += DepthCabinet * product.variations[1].options[0].additionalPrice;
      totalPrice += WidthCabinet * product.variations[2].options[0].additionalPrice;
      // totalPrice += ProfileHeight * product.variations[3].options[0].additionalPrice;
// Check if ProfileHeight is selected
      if (ProfileHeight) {
  // Look up the selected ProfileHeight option in the product's variations data
      const profileHeightOption = product.variations.find(variation => variation.name === "ProfileHeight").options.find(option => option.option === ProfileHeight);
  
  // If the selected ProfileHeight option is found, multiply the total price by its additional price
  if (profileHeightOption) {
    totalPrice *= (profileHeightOption.additionalPrice/10);
  }
}

// Check if NumberDoors is selected
if (NumberDoors) {
  //for every NumberDoors selected, add the additional price to the total price
  totalPrice += NumberDoors * product.variations[6].options[0].additionalPrice;

}



      break;


    default:
      break;
  }

  // Pass the calculated price to the parent component
  setCalculatedPrice(totalPrice);

  return <p>{totalPrice.toLocaleString("en-GB", { style: "currency", currency: "EUR" })}</p>;
};

export default ProductPrice;
