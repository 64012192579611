import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const CardProjects = ({ project, widthCombo , orderList}) => {
  const navigate = useNavigate();
  const {
    orderItems,
  } = project;

  console.log (project)
  const [widthClass1, widthClass2] = widthCombo || ["w-1/3", "w-2/3"];
  const totalPrice = orderItems?.[0]?.price ? orderItems[0].price * orderItems[0].qty : 0;

  const handleProductClick = () => {
    // go to /product/:id with the id dynamic
    navigate(`/product/${orderItems[0].product}`);
    

    
  };

  const handleProjectClick = () => {
   // go to /project/:id with the id dynamic
    window.location.href = `http://localhost:3000/project/${project._id}`;


   
  };

  const [isHoveredA, setIsHoveredA] = useState(false);
  const [isHoveredB, setIsHoveredB] = useState(false);

  const handleMouseEnterA = () => {
    setIsHoveredA(true);
  };

  const handleMouseLeaveA = () => {
    setIsHoveredA(false);
  };

  const handleMouseEnterB = () => {
    setIsHoveredB(true);
  };

  const handleMouseLeaveB = () => {
    setIsHoveredB(false);
  };

  return (
    <div className="flex flex-col w-full nav-left nav-right nav-bottom text-2xl">
      <div className="flex w-full h-full bg-white nav-bottom">
        <div className="flex justify-between w-full flex-col lg:flex-row">
          <Link to={`/product/${orderItems[0].product}`}
            className={`${widthClass1} px-5 py-2 text-default cursor-pointer font-thin `}
       
          >
            <span className="font-bold ">Item &nbsp;</span>{" "}
            {orderItems?.[0]?.name || "No Item Name Available"}
          </Link>
          <div className={`lg:nav-left flex ${widthClass2} justify-between`}>
            <div
              className={`w-11/12  text-default font-thin p-5 pt-0 pb-0 pr-0  flex justify-start nav-right h-full flex-row nav-top lg:nav-top-hidden`}
            >
              <span className="font-bold my-auto flex">Project: &nbsp;
              <div className="text-default"> {project.name || "De Knal Project"}
              </div> 
              
              
              </span>
              
            </div>
            <div
              className={` text-default  p-2 pt-0 pb-0 pr-0 flex justify-end font-thin my-auto nav-top lg:nav-top-hidden`}
            >
               {totalPrice > 0 ? (
                <>
                  €{`${totalPrice % 1 === 0 ? totalPrice : totalPrice.toFixed(2)}`}
                </>
              ) : (
                <span className="text-sm">Price on request</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-start flex-col lg:flex-row">
        <div
          className={`blok1 h-[30rem] ${widthClass1} cursor-pointer relative`}
          style={{
            backgroundImage: `url('/images/projectImages/${project._id}/AProjectFrontImage.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={handleProjectClick}
          onMouseEnter={handleMouseEnterA}
          onMouseLeave={handleMouseLeaveA}
        >
          {isHoveredA && (
            <div className="absolute inset-0 bg-black opacity-70" />
          )}
          {isHoveredA && (
            <div className="absolute inset-0 flex items-end justify-start">
              <div className="p-5 text-default text-2xl text-white">see full project</div>
            </div>
          )}
        </div>

        <div
          className={`blok2 lg:nav-left h-[30rem] ${widthClass2} cursor-pointer relative`}
          style={{
            backgroundImage: `url('/images/projectImages/${project._id}/isoHigh.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={handleProjectClick}
          onMouseEnter={handleMouseEnterB}
          onMouseLeave={handleMouseLeaveB}
        >
          {isHoveredB && (
            <div className="absolute inset-0 bg-black opacity-10" />
          )}
          {isHoveredB && (
            <div className="absolute inset-0 flex items-end justify-start">
              <div className="p-5 text-default text-2xl text-white">see full project</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardProjects;
