import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../actions/orderActions";
import { useOrder } from "../components/Context/OrderContext";
import Loader from "../components/loader";
import Message from "../components/message";
import { useState } from "react";

const MyOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  // Inside ProfileScreen component
  const { setOrderId } = useOrder();

  const [isExpiredOrders, setIsExpiredOrders] = useState(false); // State to track whether to show expired orders


  useEffect(() => {
    dispatch(listMyOrders());
  }, [dispatch]);

  const toggleExpiredOrders = () => {
    setIsExpiredOrders((prevState) => !prevState); // Toggles between true and false
  };

  const ValidOrder = (order) => {
    if (isExpiredOrders) {
      // If showing expired orders, return true for all orders
      return true;
    } else {
      // Otherwise, check if the order is within 14 days
      const createdAtDate = new Date(order.createdAt);
      const currentDate = new Date();
      const differenceInDays = Math.floor(
        (currentDate - createdAtDate) / (1000 * 60 * 60 * 24)
      );
      return differenceInDays <= 14;
    }
  };

  return (
    <div className="w-full  text-default">
      <div className="w-full text-6xl nav-bottom p-5">
        <p className="">Orders</p>
      </div>

      {loadingOrders ? (
        <Loader />
      ) : errorOrders ? (
        <Message variant="danger">{errorOrders}</Message>
      ) : orders.length === 0 ? (
        <Message variant="info">You haven't placed any request yet.</Message>
      ) : (
        <div className="w-full overflow-y-auto">
          <table className="w-full text-default">
            <tbody className="w-full">
              {orders
              .filter(ValidOrder)
              .map((order) => (
                <tr
                  key={order._id}
                  className="flex flex-col w-full h-full nav-bottom p-5"
                >
                  <td className="flex flex-col w-full  nav p-5">
                    <Link to={`/order/${order._id}`} className="w-full">
                      Order Id 
                      <br/> 
                      {order._id}
                      <br/>
                      <br/>
                    </Link>
                    <Link to={`/order/${order._id}`} className="font-thin ml-2">
  {order.orderItems.some((item) => item.variant)
    ? order.orderItems
        .filter((item) => item.variant)
        .flatMap((item) => {
          
          
            const variations = [];
            const filteredVariants = order.orderItems.filter((orderItem) => orderItem.variant);

            filteredVariants.forEach((orderItem, index) => {
                variations.push(
                    <div className="font-normal">Item: {index + 1}  </div>
                );

                Object.entries(orderItem.variant).forEach(([key, value]) => {
                    variations.push(`${value}  `);
                });

                variations.push(
                    <div className="ml-5 font-normal">
                        Price: €{orderItem.price.toFixed(2)} <br/> <br/> 
                    </div>
                );
            });

            return variations;

        })
    : "No Variations"}
</Link>


<Link to={`/order/${order._id}`} className="">
  Order Date:{" "}
  {new Date(order.createdAt).toISOString().split("T")[0]}
  <br />
  Validity:{" "}
  {isExpiredOrders
    ? "Expired"
    : `${Math.floor(
        (new Date() - new Date(order.createdAt)) / (1000 * 60 * 60 * 24)
      )} days`}
  <br />
  <br />
</Link>


                    <Link to={`/order/${order._id}`} className="">
 
  {/* Display order status */}
  Status:
  <div className="justify-between w-full flex">
    
  {order.isHandledByPlatform ? <span className="">Handled by Platform</span> : <span className="opacity-30">Handled by Platform</span>}
  {order.isPaid ? <span className="">Paid</span> : <span className="opacity-30">Paid</span>}
  {order.isDelivered ? <span className="">Delivered</span> : <span className="opacity-30">Delivered</span>}
</div>
<br /><br />

  

</Link>


                    <Link to={`/order/${order._id}`} className="btn w-1/2 ml-auto">
                      Details
                    </Link>

                  </td>

                  <td className="w-1/3 ml-auto">
                    
                  </td>
                </tr>
                
              ))}
             
             <div className="p-5 flex">
            <button
              className="btn ml-auto mr-10 w-1/2"
              onClick={toggleExpiredOrders}
            >
              {isExpiredOrders ? "Hide Expired Orders" : "See Expired Orders"}
            </button>
          </div>
            </tbody>
           
          </table>
         

        </div>
      )}
    </div>
  );
};

export default MyOrders;
