import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCES,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCES,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCES,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCES,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCES,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCES,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCES,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  ARTIST_DETAILS_REQUEST,
  ARTIST_DETAILS_SUCCES,
  ARTIST_DETAILS_FAIL,
  ARTIST_DETAILS_RESET,
  ARTIST_LIST_REQUEST,
  ARTIST_LIST_SUCCES,
  ARTIST_LIST_FAIL,
  ARTIST_LIST_RESET
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCES:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCES:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCES:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCES:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCES:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };

    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCES:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCES:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const artistDetailsReducer = (
  state = {
    artist: {},
    loading: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case ARTIST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARTIST_DETAILS_SUCCES:
      return {
        ...state,
        loading: false,
        artist: action.payload,
      };
    case ARTIST_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ARTIST_DETAILS_RESET:
      return {
        ...{
          artist: {},
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export const artistListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case ARTIST_LIST_REQUEST:
      return { ...state, loading: true };
    case ARTIST_LIST_SUCCES:
      return { loading: false, users: action.payload };
    case ARTIST_LIST_FAIL:
      return { loading: false, error: action.payload, users: []  };
    case ARTIST_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};