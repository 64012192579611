import React, { useState } from "react";
import { Link } from "react-router-dom";

const CardProjectsSmall = ({ project }) => {
  const {

  
 orderItems,
  } = project;

  
  const handleProductClick = () => {
    window.location.href =
    // link to the product/products._id
      `http://localhost:3000/product/${orderItems?.[0]?._id}`;
  };

  const handleProjectClick = () => {
    window.location.href =
    //  link to projects/projects._id
      `http://localhost:3000/project/${project._id}`;
      
  };

  const [isHoveredA, setIsHoveredA] = useState(false);

  const handleMouseEnterA = () => {
    setIsHoveredA(true);
  };

  const handleMouseLeaveA = () => {
    setIsHoveredA(false);
  };

  // Inline style for the grid container to achieve full viewport height minus 100px
const gridStyle = {
  minheight: '100%'
  };


  return (
    <div className="flex flex-col w-full h-full min-h-96 nav">
      <div className="flex w-full h-16 bg-white">
        <div className="flex justify-between w-full">
          <div
            className={` nav-bottom w-full p-3 pt-0 pb-0 pr-0 text-default  HeaderTextColumn HeaderTextColumnHoover cursor-pointer `}
            onClick={handleProductClick}
          >
            <span className="font-bold">Item &nbsp;</span>{" "}
            {orderItems?.[0]?.name || "No Item Name Available"}
          </div>
        </div>
      </div>

      <div className="flex w-full justify-start flex-grow"> {/* Apply flex-grow */}
        <Link to={`/project/${project._id}`}
          className={` w-full cursor-pointer relative`}
          style={{
            height: gridStyle.height, // Correctly reference the height property,
            //backgroundImage: `url('${AProjectFrontImage}')`,
            backgroundImage: `url('/images/projectImages/${project._id}/AProjectFrontImage.jpeg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={handleProjectClick}
          onMouseEnter={handleMouseEnterA}
          onMouseLeave={handleMouseLeaveA}
        >
          {isHoveredA && (
            <div className="absolute inset-0 bg-black opacity-70" />
          )}
          {isHoveredA && (
            <div className="absolute inset-0 flex items-end justify-start">
              <div className="p-10 text-default text-2xl text-white">
                see full project
              </div>
            </div>
          )}
        </Link>
      </div>
    </div>
  );
};

export default CardProjectsSmall;
