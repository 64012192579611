import React, { useEffect } from 'react';
import CardProjectsSmall from '../components/CardProjectsSmall';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrdersWithImages } from '../actions/projectActions';


const ProjectListScreen = ({CarouselItems}) => {
  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.ordersWithImages);
  const { loading, error, orders: ordersWithImages } = orderList;

  useEffect(() => {
    dispatch(fetchOrdersWithImages());
  }, [dispatch]);

  // Slice the orders array to contain only 2 elements
  const limitedOrders = ordersWithImages.slice(0, CarouselItems);

  return (
    <div className="h-full w-full mb-10">
      {loading ? (
        <p>Loading projects...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        <div className="flex flex-col lg:flex-row  w-full h-grow gap-10">
          {limitedOrders.map((project) => (
            <CardProjectsSmall key={project._id} project={project} />
          ))}
          
        </div>
        
      )}
    </div>
  );
};

export default ProjectListScreen;