import React, { useEffect, useState } from 'react';

const ScreenSizeHandler = () => {
    const [screenSize, setScreenSize] = useState('');

    useEffect(() => {
        const handleResize = () => {
            const tailwindScreenSizes = {
                'sm': '640px',
                'md': '768px',
                'lg': '1024px',
                'xl': '1280px',
                '2xl': '1536px',
            };

            const currentSize = Object.entries(tailwindScreenSizes).find(([_, size]) => {
                return window.innerWidth >= parseInt(size, 10);
            });

            if (currentSize) {
                setScreenSize(currentSize[0]);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log('Current Tailwind screensize:', screenSize);
    }, [screenSize]);

    return null;
};

export default ScreenSizeHandler;