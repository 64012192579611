import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProductsList from "../components/ProductsList";

const HomeScreen = () => {
  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  return (
    <div>
      <div className="flex w-full justify-start h-[15rem]">
        <div className="blok1 nav-left nav-standard w-2/3 p-5 relative">
          {/* Content for the left column */}
          <p className="text-default text-6xl">All products</p>
          <p className="text-default font-thin text-2xl">
            And everything customizable
          </p>

         
        </div>
        <div className="nav-standard justify-left items-top p-5 w-1/3 relative">
          <p className="text-default text-2xl">
            Our {products.length} products,
          </p>
          <p className="text-default font-thin text-2xl">
            or get inspired by our projects
          </p>

          <div className="absolute bottom-0 left-0 right-0 p-5 flex justify-start gap-5">
            <Link to="/projects" className="btn">
              See the projects
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full">
        {/* Added margin-top for spacing */}
        <ProductsList />
      </div>
    </div>
  );
};

export default HomeScreen;
