import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import Message from "../components/message";
import Loader from "../components/loader";
import { emptyCart } from "../actions/cartActions";
import ProductScreenDrag from "../components/ProductScreenDrag";
import ProductWithVariants from "../components/ProductWithVariants";
import ArtistDetails from "../components/ArtistDetails";

const OrderScreenTest = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const containerClass = "py-2 text-default  ";
  const inputStyles = "border p-2 w-full"; // Removed 'rounded-md' class
  const fontThin = "font-thin pl-5 text-default";
  const TextTitle = "text-5xl text-default";

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: succesDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [clickedIndex, setClickedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleButtonClick = (index) => {
    setClickedIndex(index);
  };

  const handleButtonHover = (index) => {
    setHoveredIndex(index);
  };

  const handleButtonLeave = () => {
    setHoveredIndex(null);
  };

  const productSelected = clickedIndex !== null ? clickedIndex : 1;

  if (!loading) {
    // Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.length > 0
        ? order.orderItems[productSelected].price *
            order.orderItems[productSelected].qty 
        : 0
    );
    
  }

  // Function to calculate total price as the sum of item price and shipping price
const calculateTotalPrice = () => {
  // Convert item price and shipping price to numbers
  const itemPrice = parseFloat(order.itemsPrice);
  const shippingPrice = parseFloat(order.shippingPrice);

  // Add item price and shipping price
  const totalPrice = itemPrice + shippingPrice;

  // Format the total price with two decimal places
  return totalPrice.toFixed(2);
};

  useEffect(() => {
    if (!order || order._id !== id) {
      dispatch(getOrderDetails(id));
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
    } else if (order && order.isPaid) {
    }
  }, [dispatch, id, order, succesDeliver]);

  const handlePayment = async () => {
    try {
      // Dispatch the payOrder action with the order ID and payment result
      await dispatch(
        payOrder(order._id, {
          id: "PAYMENT_ID_FROM_PAYMENT_GATEWAY", // Replace with the actual payment ID from the payment gateway
          status: "COMPLETED", // Assuming payment is completed
          update_time: new Date().toISOString(), // Current timestamp
          payer: {
            email_address: order.user.email, // Use the email address of the user who placed the order
          },
        })
      );

      // If payment is successful, fetch updated order details
      dispatch(getOrderDetails(order._id));
      // Empty the cart after successful payment
      dispatch(emptyCart());
    } catch (error) {
      // Handle payment failure
      console.error("Payment failed:", error);
      // Optionally, display an error message to the user
    }
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  // Function to determine the status of the order
  const getStatusOrder = () => {
    if (order.isDelivered) {
      return "Delivered";
    } else if (order.isPaid) {
      return "Paid";
    } else {
      return "To be Paid";
    }
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div className="flex lg:h-custom nav">
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full  nav-bottom text-default h-24 lg:h-min">
          <div className="w-full flex lg:flex-row flex-col relative px-5 py-2 justify-between">
            <p>Order: {id}</p>
            <ProductWithVariants
              orderItems={order.orderItems}
              productSelected={productSelected}
            />
            <div className="flex">
              <p>{getStatusOrder()}</p>
            </div>
          </div>
        </div>

        {/* Lower row with blue and red sections, using 2/3 of total height */}
        <div className="flex w-full h-full  flex-col lg:flex-row">
          <div
            className="lg:nav-right w-full lg:w-2/3 h-custom-lg lg:h-full relative flex flex-col justify-center"
            style={{
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/CompanyImages/logoDeKnalAchtergrond.png)", // Add linear gradient with 50% opacity over the background image
              backgroundSize: "50%", // Set background size to 50%
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Set background repeat to no-repeat
              position: "relative",
            }}
          >
            {/* Inside the ProductScreenDrag component, pass the productId as a prop, also receive a variant = {order}  */}
            <ProductScreenDrag
              variant="order"
              id={id}
              selectedIndex={productSelected}
              order = {order}
            />

            <div className="absolute top-0 left-0 flex flex-col lg:flex-row items-center p-5 z-50">
              {order.orderItems.map((item, index) => (
                <button
                  key={index}
                  className={`btn mb-3 lg:mb-0 mr-3 bg-white ${
                    clickedIndex === index ? "btn-negative" : ""
                  }`}
                  onClick={() => handleButtonClick(index)}
                >
                  {`Version ${index + 1}`}
                </button>
              ))}
            </div>

            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center">
  <span className="text-default text-2xl font-thin pr-3">&lt;</span>
  <p className="text-default p-10 text-2xl font-bold">
    Drag to rotate
  </p>
  <span className="text-default text-2xl font-thin pl-3">&gt;</span>
</div>

          </div>
          <div className="w-full lg:w-1/3 h-full relative flex flex-col justify-end items-end ">
            <div className="w-full h-auto nav-top">
              {/* Separate containers with different heights */}

              <div className={`h-auto flex flex-col justify-between`}>
                <div className="p-5 text-default nav-under space-y-1">
                  <p className={TextTitle}>Quote Product(s)</p>
                  <br/>
                  <div className="flex flex-col">
                  
                    
                    <div className="flex">
                    {<p className="text-2xl">{order.shippingPrice}</p>}
                    <p className={`${fontThin} text-2xl`}>{`shipping`}</p>
                    </div>
                    
                    <div className="flex">
                    {<p className="text-2xl">{order.itemsPrice}</p>}
                    <p className={`${fontThin} text-2xl`}>{`(inc eur VAT)`}</p>
                    </div>
                    <br/>

                    <div className="flex  ml-auto">
                    <p className="text-2xl">{calculateTotalPrice()}</p>
                    <p className={`${fontThin} text-2xl`}>{`total`}</p>
                    </div>
                    
                  </div>
                  <p className="font-thin"></p>
                  <div className="w-full ml-auto flex items-end justify-end">
                    {" "}
                    {/* Added flex class */}
                    {!order.isPaid && (
                      <button
                        className="btn w-1/2 mt-4"
                        onClick={handlePayment}
                      >
                        Pay Now
                      </button>
                    )}
                    {userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                      <button
                        onClick={deliverHandler}
                        className="btn w-40 mt-4"
                      >
                        Mark as Delivered
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderScreenTest;
