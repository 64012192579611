import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import Message from './message';
import Loader from './loader';
import InformationContainer from '../components/InformationContainer';
import { Link, useNavigate } from 'react-router-dom';
import ToggleComponent from './ToggleComponent';

const MarketingPreferenceScreen = () => {
  const [isMarketingSubscribed, setIsMarketingSubscribed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    if (!user.name) {
      dispatch(getUserDetails('profile'));
    } else {
      setIsMarketingSubscribed(user.marketingPref || false);
    }
  }, [dispatch, user]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserProfile({ id: user._id, marketingPref: isMarketingSubscribed }));
    navigate('/profile'); // Redirect back to profile after update
    window.location.reload();
  };

  return (
    <div className="container p-10">
      <div className="flex flex-row">
        <InformationContainer className="w-full bg-red-200 text-default">
          <div className='flex w-full'>
            <Link to='/profile' className="btn w-40 mr-auto mb-10 text-default">
              Go Back
            </Link>
          </div>
          <h1 className="text-xl font-bold mb-4 text-default">Marketing Preferences</h1>
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}

          <form onSubmit={submitHandler} className='w-1/2'>
            <div className="w-full">
              <label className="block text-default my-2">
                Receive Marketing Updates?
              </label>
              {/* Integrate the ToggleComponent here */}
              <ToggleComponent
                name="marketingPref"
                isChecked={isMarketingSubscribed}
                onChange={setIsMarketingSubscribed}
              />
            </div>
            <div className='flex w-full'>
              <button type="submit" className="btn w-40 ml-auto">
                Update
              </button>
            </div>
          </form>
        </InformationContainer>
      </div>
    </div>
  );
};

export default MarketingPreferenceScreen;
