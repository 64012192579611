// this is profileScreen, everytime a orderId gets clicked, a new value gets passed to useOrder right?
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/message';
import Loader from '../components/loader';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { listMyOrders } from '../actions/orderActions';
import ChatScreen from '../components/ChatBox';
import { useOrder } from '../components/Context/OrderContext';

const ProfileScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;
  
  // Inside ProfileScreen component
  const { setOrderId } = useOrder();


  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!user.name) {
        dispatch(getUserDetails('profile'));
        dispatch(listMyOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, navigate, userInfo, user.name, user.email]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }))

        .then(() => {
          setSuccessMessage('Profile updated successfully!');
        })
        .catch((error) => {
          console.error('Error updating profile:', error);
        });
    }
  };

  return (
<>
<Row>
      <Col md={3}>
        <h2>My Orders</h2>
        {loadingOrders ? <Loader /> : errorOrders ? <Message variant='danger'>{errorOrders}</Message> : (
          <Table striped bordered hover responsive className='table-sm'>
         <thead>
  <tr>
    <th>ID</th>
    {/* <th>DATE</th> */}
    {/* <th>TOTAL</th> */}
    {/* <th>PAYED</th> */}
    {/* <th>DELIVERED</th> */}
    <th></th>
  </tr>
</thead>
<tbody>
  {orders.map(order => (
    <tr key={order._id}>
      <td>
     
      <LinkContainer
          to={`/order/${order._id}`}
          onClick={(event) => {
            event.preventDefault();
            setOrderId(order._id);
          }}
        >
          <a 
            href={`/order/${order._id}`} 
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            {order._id}
          </a>
</LinkContainer>

      </td>
      {/* Other columns */}
      <td>
        <LinkContainer to={`/order/${order._id}`}>
          <Button variant='light'>Details</Button>
        </LinkContainer>
      </td>
    </tr>
  ))}
</tbody>
          </Table>
        )}
      </Col>
      <Col md={6} style={{ backgroundColor: 'lightblue' }}>
      <ChatScreen  />
     </Col>
     <Col md={3} style={{ backgroundColor: 'red' }}>
     </Col>


    </Row>
</>
  );
};

export default ProfileScreen;
