import React from 'react';

const TextBox = ({ version, headerText, bodyText }) => {
  if (version) {
    return (
      <div className="h-full w-full bg-black flex items-start justify-start py-10 pl-5 pr-24 nav-standard border-white">
        <div>
          <h1 className="text-default text-white text-4xl">{headerText}</h1>
          <p className="text-default text-white text-xl font-light">{bodyText}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-full w-full flex items-start justify-start p-3 nav-standard">
        <div>
          <h1 className="text-default  text-2xl">{headerText}</h1>
          <p className="text-default font-light">{bodyText}</p>
        </div>
      </div>
    );
  }
};

export default TextBox;
