// src/services/chatService.js

import axios from 'axios';

const createChatToken = async (userId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const { data } = await axios.post('/api/chat/token', { userId }, config);
  return data.token;
};

const chatService = {
  createChatToken,
};

export default chatService;