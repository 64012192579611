import React from "react";
import ProfileContainer from "../components/ProfileContainer";
import ArtistGridContainer from "../components/ArtistGridContainer";
import OrdersInProcess from "../components/OrdersInProcess"; // Import OrdersInProcess component
import EditProfile from "../components/EditProfile"; // Import EditProfile component
import ChangeEmail from "../components/ChangeEmail"; // Import ChangeEmail component
import DigitalInvoice from "../components/DigitalInvoice"; // Import DigitalInvoice component
import Rates from "../components/Rates"; // Import Rates component
import Reviews from "../components/Reviews"; // Import Reviews component
import DeleteAccount from "../components/DeleteAccount";
import Availability from "../components/Availability";
import { useParams } from "react-router-dom";
import ScreenSizeHandler from "../components/ScreenSizeHandler";
const DashboardScreen = () => {
  const { id } = useParams();
  
  let childAComponent;

  // Conditionally set childAComponent based on the URL parameter
  switch (id) {
    case "edit":
      childAComponent = <EditProfile />;
      break;

    case "change-email":
      childAComponent = <ChangeEmail />;
      break;

      case "availability":
      childAComponent = <Availability />;
      break;
    
    case "rates":
      childAComponent = <Rates />;
      break;
    
      case "reviews":
      childAComponent = <Reviews />;
      break;
      
      case "delete":
      childAComponent = <Rates />;
      break;

    default:
      childAComponent = <ArtistGridContainer />;
      break;
  }


  return (
    <div className="">
      <ProfileContainer childA={childAComponent} childB={<OrdersInProcess />} />
      <ScreenSizeHandler />
    </div>
  );
};

export default DashboardScreen;
