import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { updateUserProfile } from '../actions/userActions';

const JoinScreen = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState('');
  const [phone, setPhone] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');

  const businessNameInputRef = useRef();
  const phoneInputRef = useRef();
  const companyWebsiteInputRef = useRef();

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (businessName.trim() === '' || phone.trim() === '' || companyWebsite.trim() === '') {
      alert('Please fill in all fields');
      return;
    }
    dispatch(updateUserProfile({
      phone,
      artistInformation: [{
        artistWebsite: companyWebsite,
        businessName,
      }],
    }))
      .then(() => {
        console.log('Profile updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
      });
  };

  return (
    <div className="text-default">
      {userInfo && userInfo.isArtist === true ? (
        <FormContainer>
          <p className="text-5xl p-5">You are already an artist!</p>
        </FormContainer>
      ) : (
        <FormContainer>
          <div className='p-5'>
            <form onSubmit={submitHandler}>
              <p className="text-5xl">Join De Knal!</p>

              <div className="py-2">
                <label htmlFor='businessName' className="text-default">Business Name</label>
                <input
                  ref={businessNameInputRef}
                  id='businessName'
                  type='text'
                  placeholder='Enter business name'
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  required
                  className="border p-2 w-full"
                />
              </div>

              <div className="py-2">
                <label htmlFor='phone' className="text-default">Phone</label>
                <input
                  ref={phoneInputRef}
                  id='phone'
                  type='number'
                  placeholder='Enter phone number'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  className="border p-2 w-full"
                />
              </div>

              <div className="py-2">
                <label htmlFor='companyWebsite' className="text-default">Company Website</label>
                <input
                  ref={companyWebsiteInputRef}
                  id='companyWebsite'
                  type='text'
                  placeholder='Enter company website'
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>

              <div
    className="btn w-1/2"
    onClick={submitHandler} // Attach the submitHandler to onClick event
    
  >
    Send!
  </div>
            </form>
          </div>
        </FormContainer>
      )}
    </div>
  );
};

export default JoinScreen;
