import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getArtistDetails } from "../actions/userActions";
import Message from "../components/message"; // Import custom Message component
import Loader from "../components/loader"; // Import custom Loader component
import { Link } from 'react-router-dom';

const ArtistScreen = () => {
  const { id: artistId } = useParams(); // Extract artist ID from URL params
  const dispatch = useDispatch();

  // Fetch artist details from Redux store
  const artistDetails = useSelector((state) => state.artistDetails);
  const { loading, error, artist } = artistDetails;

  useEffect(() => {
    // Dispatch action to fetch artist details when the component mounts
    dispatch(getArtistDetails(artistId));
  }, [dispatch, artistId]);

  return (
    <div>
      {loading && <Loader />} {/* Display custom loader if loading */}
      {error && <Message variant="danger">{error}</Message>}{" "}
      {/* Display custom message if error */}
      {!loading &&
        !error &&
        artist &&
        artist.artistInformation &&
        artist.artistInformation.length > 0 && (
          <div>
            <div className="flex w-full justify-start h-[22rem]">
              <div className="blok1 nav-left nav-standard w-2/3 p-5 relative">
                {/* Content for the left column */}
                <p className="text-default text-6xl">
                  {artist.artistInformation[0].artistName}
                </p>
                <p className="text-default text-xl font-thin">
                  {artist.artistInformation[0].artistBio}
                </p>
                {/* Buttons placed at the bottom */}
                <div className="absolute bottom-0 left-0 right-0 p-5 flex justify-center gap-5">
                <Link to="/projects" className="btn w-1/2">See my projects</Link>
                 <Link to="/products" className="btn w-1/2">Check the products</Link>
                </div>
              </div>
              <div className="blok2 nav-standard justify-left items-top p-5 w-1/3">
                {/* Content for the right column */}
                <p className="text-default text-2xl">Location:</p>{" "}
                <p className="text-default font-thin text-2xl">Zuid Holland</p>
                <p className="text-default text-2xl">With us since:</p>{" "}
                <p className="text-default font-thin text-2xl">March 2024</p>
              </div>
            </div>

            {/*end of the top head*/}

            <div className="flex w-full justify-start h-[25rem]">
              <div
                className="blok1 nav-left nav-standard h-full w-1/3"
                style={{
                  backgroundImage: `url(${artist.artistInformation[0].artistImageProfile})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>{" "}
              {/* Blue square */}
              <div
                className="blok2 nav-standard h-full w-2/3"
                style={{
                  backgroundImage: `url(${artist.artistInformation[0].artistImageWithProductA})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>{" "}
              {/* Red square */}
            </div>

            <div className="flex w-full justify-start h-[25rem]">
              <div
                className="blok1 nav-left nav-standard h-full w-1/2"
                style={{
                  backgroundImage: `url(${artist.artistInformation[0].artistImageWithProductB})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>{" "}
              {/* Blue square */}
              <div className="blok2 nav-standard h-full w-1/2 relative p-5">
                {/* Text in the left upper corner */}
                <p className="text-default text-2xl">Experience:</p>{" "}
                <p className="text-default font-thin text-2xl">
                  {artist.artistInformation[0].artistCV}
                </p>
              </div>
              {/* Red square */}
            </div>
          </div>
        )}
    </div>
  );
};

export default ArtistScreen;
