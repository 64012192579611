import React from 'react';
import { Spinner } from 'react-bootstrap'; // Correctly import Spinner with a capital "S"

// Functional component to display a loading spinner
const Loader = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default Loader;