import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersArtist } from '../actions/orderActions';
import Loader from './loader';
import Message from './message';
import { Link } from 'react-router-dom';

const ArtistOrdersScreen = () => {
  const dispatch = useDispatch();

  const artistOrders = useSelector((state) => state.artistOrders);
  const { loading, error, orders } = artistOrders;

  useEffect(() => {
    dispatch(getOrdersArtist());
  }, [dispatch]);

//define the orderdate + 3week
  const orderDate = new Date();
  const deliveryDate = new Date(orderDate);
  deliveryDate.setDate(deliveryDate.getDate() + 21);
  const formattedDeliveryDate = deliveryDate.toISOString().split("T")[0];



  return (
    <div className="w-full  text-default">
      <div className="w-full text-6xl nav-bottom p-5">
        <p className="">Orders</p>
      </div>

      {loading ? (
        <Loader />
      ) : loading ? (
        <Message variant="danger">{error}</Message>
      ) : orders.length === 0 ? (
        <Message variant="info">You haven't placed any request yet.</Message>
      ) : (
        <div className="w-full overflow-y-auto">
          <table className="w-full text-default">
            <tbody className="w-full">
              {orders
              
              .map((order) => (
                <tr
                  key={order._id}
                  className="flex flex-col w-full h-full nav-bottom p-5"
                >
                  <td className="flex flex-col w-full  nav p-5">
                    <Link to={`/order/${order._id}`} className="w-full">
                      Order Id 
                      <br/> 
                      {order._id}
                      <br/>
                      <br/>
                    </Link>
                    <Link to={`/order/${order._id}`} className="font-thin ml-2">
  {order.orderItems.some((item) => item.variant)
    ? order.orderItems
        .filter((item) => item.variant)
        .flatMap((item) => {
          
          
            const variations = [];
            const filteredVariants = order.orderItems.filter((orderItem) => orderItem.variant);

            filteredVariants.forEach((orderItem, index) => {
                variations.push(
                    <div className="">Item: {index + 1}  </div>
                );

                Object.entries(orderItem.variant).forEach(([key, value]) => {
                    variations.push(`${value}  `);
                });

                variations.push(
                    <div className="ml-5 font-thin">
                        Price: €{orderItem.price.toFixed(2)} <br/> <br/> 
                    </div>
                );
            });

            return variations;

        })
    : "No Variations"}
</Link>


<Link to={`/order/${order._id}`} className="">
  Order Date:{" "}
  {new Date(order.createdAt).toISOString().split("T")[0]}
  <br />
 
 

  {order.isDelivered ? <span className=""></span> : <span className="">Order work:  25 hours
  <br />
  <br />
  Expected delivery: {formattedDeliveryDate}
  <br /></span>}

</Link>


                    <Link to={`/order/${order._id}`} className="">
 
  {/* Display order status */}
  Status:
  <br />
  <div className="justify-between w-full flex">
  {order.isPaid ? <span className="">Paid</span> : <span className="opacity-30">Ready To Make</span>}
  {order.isDelivered ? <span className="">Delivered</span> : <span className="opacity-30">Delivered</span>}
</div>
<br /><br />

  

</Link>


                    <Link to={`/order/${order._id}`} className="btn w-1/2 ml-auto">
                      Details
                    </Link>

                  </td>

                  <td className="w-1/3 ml-auto">
                    
                  </td>
                </tr>
                
              ))}
             
             <div className="p-5 flex">
           
          </div>
            </tbody>
           
          </table>
         

        </div>
      )}
    </div>
  );
};


export default ArtistOrdersScreen;
