import React, { useState, useEffect } from 'react';

const ProductScreenDrag = ({ id, variant, selectedIndex = 0, order }) => {
  let imageUrls;

  switch (variant) {
    case 'order':
      imageUrls = Array.from(
        { length: 15 },
        (_, i) => `/images/dropbox/OrdersImages/${order}/${id}/F${i + 1}.png`
      );
      break;
    case 'product':
      imageUrls = Array.from(
        { length: 15 },
        (_, i) => `/images/productImages/${id}/F${i + 1}.png`
      );
      break;
    default:
      break;
  }

  const [loading, setLoading] = useState(true);
  const [holdingClick, setHoldingClick] = useState(false);
  const [hoveredContainer, setHoveredContainer] = useState(selectedIndex); // Start from the last selected index
  const [selectedImageUrl, setSelectedImageUrl] = useState(imageUrls[selectedIndex]); // Start with the image of the last selected index
  const colorContainerClasses =
    'w-1/3 h-full inline-block opacity-30 unselectable ';

  const handleMouseDown = (event) => {
    event.preventDefault(); // Prevent default behavior
    setHoldingClick(true);
  };

  const handleMouseUp = (event) => {
    event.preventDefault(); // Prevent default behavior
    setHoldingClick(false);
  };

  const handleTouchStart = (event) => {
    event.preventDefault(); // Prevent default behavior
    setHoldingClick(true);
  };

  const handleTouchEnd = (event) => {
    event.preventDefault(); // Prevent default behavior
    setHoldingClick(false);
  };

  const handleMouseEnter = (containerIndex) => {
    // Check if the user is holding the mouse click or touch
    if (holdingClick) {
      // Calculate the index of the next hovered container
      let nextHoveredContainer = containerIndex;

      // If the container index exceeds the length of URLs, adjust it
      if (containerIndex >= imageUrls.length) {
        nextHoveredContainer = containerIndex % imageUrls.length;
      }

      // Update the hovered container state
      setHoveredContainer(nextHoveredContainer);

      // Update the selected image URL based on the next hovered container
      setSelectedImageUrl(imageUrls[nextHoveredContainer]);
    }
  };

  const containerStyle = {
    backgroundImage: `url(${selectedImageUrl})`,
    backgroundSize: variant === 'product' ? '100%' : '150%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    transform: hoveredContainer >= 15 ? 'scaleX(-1)' : 'none',
    cursor: holdingClick ? 'grabbing' : 'grab',
    overflow: 'hidden', // Ensure no overflow
  };

  useEffect(() => {
    // Preload images
    const preloadImages = async () => {
      try {
        let successCount = 0; // Initialize success count

        await Promise.all(
          imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = () => {
                successCount++; // Increment success count on successful load
                resolve();
              };
              img.onerror = reject;
            });
          })
        );

        // Set loading to false once images are loaded
        setLoading(false);
      } catch (error) {
        // Set loading to false and display the error message
        setLoading(false);
      }
    };

    preloadImages();
  }, [imageUrls]);

  // Check if imageUrls length is 0
  if (imageUrls.length === 0) {
    return <div>Your quote is processed</div>;
  }

  // Check if loading
  if (loading) {
    return <div>Loading... Please wait</div>;
  }

  // Default return if neither loading nor imageUrls length is 0
  return (
    // Check if order is something
    !order ? (
      <div
        className="w-full h-full relative overflow-hidden" // Ensure the container itself doesn't cause overflow
        style={containerStyle}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className="absolute inset-0 flex justify-center items-center">
          {Array.from({ length: 25 }, (_, index) => (
            <div
              key={index}
              className={colorContainerClasses}
              onMouseEnter={() => handleMouseEnter(index)}
              onTouchMove={() => handleMouseEnter(index)}
            >
              {/* Render content for each container */}
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="flex w-1/3 lg:w-full h-full ml-auto mr-20 lg:mr-0">
        <p className="text-default text-5xl my-auto lg:m-auto text-center z-40 w-full">
          Your order is processed <br /> <br /> Please wait!
        </p>
      </div>
    )
  );
};

export default ProductScreenDrag;
