// src/screens/MyOrders.js

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../actions/orderActions";
import { useOrder } from "../components/Context/OrderContext";
import Loader from "../components/loader";
import Message from "../components/message";
import generateInvoice from "../utils/generateInvoice";

const MyOrders = () => {
  const dispatch = useDispatch();

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    dispatch(listMyOrders());
  }, [dispatch]);

  // Function to filter only delivered orders
  const ValidOrder = (order) => order.isDelivered;

  const handleDownloadInvoice = (order) => {
    generateInvoice(order);
  };

  return (
    <div className="w-full text-default">
      <div className="w-full text-6xl nav-bottom">
        <p className="m-10">Order history</p>
        <div className='flex w-full m-10'>
            <Link to='/profile' className="btn w-40 text-default">
              Go Back
            </Link>
          </div>

      </div>

      {loadingOrders ? (
        <Loader />
      ) : errorOrders ? (
        <Message variant="danger">{errorOrders}</Message>
      ) 
        : orders.length === 0 ? (
          <Message variant="info">You haven't placed any orders yet.</Message>
        ) : (
        <div className="w-full overflow-y-auto">
         
          <table className="w-full text-default">
          
          

            <tbody className="w-full">
            

              {orders.filter(ValidOrder).map((order) => (
                <tr
                  key={order._id}
                  className="flex flex-col w-full h-full nav-bottom p-5"
                >
                  <td className="flex flex-col w-full nav p-5">
                    <Link to={`/order/${order._id}`} className="w-full">
                      Order Id
                      <br />
                      {order._id}
                      <br />
                      <br />
                    </Link>
                    <Link to={`/order/${order._id}`} className="font-thin ml-2">
                      {order.orderItems.some((item) => item.variant)
                        ? order.orderItems
                            .filter((item) => item.variant)
                            .flatMap((item) => {
                              const variations = [];
                              const filteredVariants = order.orderItems.filter(
                                (orderItem) => orderItem.variant
                              );

                              filteredVariants.forEach((orderItem, index) => {
                                variations.push(
                                  <div className="font-normal">
                                    Item: {index + 1}{" "}
                                  </div>
                                );

                                Object.entries(orderItem.variant).forEach(
                                  ([key, value]) => {
                                    variations.push(`${value}  `);
                                  }
                                );

                                variations.push(
                                  <div className="ml-5 font-normal">
                                    Price: €{orderItem.price.toFixed(2)} <br />{" "}
                                    <br />
                                  </div>
                                );
                              });

                              return variations;
                            })
                        : "No Variations"}
                    </Link>

                    <Link to={`/order/${order._id}`} className="">
                      Order Date:{" "}
                      {new Date(order.createdAt).toISOString().split("T")[0]}
                      <br />
                      <br />
                    </Link>

                    <Link to={`/order/${order._id}`} className="">
                      {/* Display order status */}
                      Status:
                      <div className="justify-between w-full flex">
                        {order.isHandledByPlatform ? (
                          <span className="">Handled by Platform</span>
                        ) : (
                          <span className="opacity-30">Handled by Platform</span>
                        )}
                        {order.isPaid ? (
                          <span className="">Paid</span>
                        ) : (
                          <span className="opacity-30">Paid</span>
                        )}
                        {order.isDelivered ? (
                          <span className="">Delivered</span>
                        ) : (
                          <span className="opacity-30">Delivered</span>
                        )}
                      </div>
                      <br />
                      <br />
                    </Link>
                    <div className="flex flex-row gap-5 ">
                      <Link to={`/order/${order._id}`} className="btn w-1/2 ml-auto mb-auto">
                        Details
                      </Link>

                      <button
                        className="btn w-1/2 ml-auto mb-auto"
                        onClick={() => handleDownloadInvoice(order)}
                      >
                        Download Invoice
                      </button>
                    </div>
                  </td>

                  <td className="w-1/3 ml-auto"></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MyOrders;
