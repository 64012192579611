import React from "react";

const ProfileContainer = ({ childA, childB }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full h-full nav">
      <div className="flex w-full h-screen overflow-y-auto lg:nav-right p-5 lg:w-4/6 nav-bottom">
        {childA}
      </div>
      <div className="flex w-full h-screen overflow-y-auto lg:w-2/6">
        {/* Render childB here */}
        {childB}
      </div>
    </div>
  );
};

export default ProfileContainer;
