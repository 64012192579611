import React from 'react';

const ProductWithVariants = ({ orderItems, productSelected }) => {
  // Check if orderItems exist and if the selected product index is valid
  const selectedProduct = orderItems && orderItems.length > 0 && productSelected >= 0 && productSelected < orderItems.length
    ? orderItems[productSelected]
    : null;

  if (!selectedProduct) {
    return <div>No product selected</div>;
  }

  const { name, variant } = selectedProduct;

  return (
    <div className="flex product-with-variants">
      <span>{name} </span> {/* Display item name */}
      {/* Check if variant object is empty */}

      {variant && Object.keys(variant).length > 0 ? (
        // Loop through variant properties
        Object.entries(variant).map(([key, value]) => (
          <span className="font-thin hidden lg:inline ml-2" key={key}>{value} </span>
        ))
      ) : (
        <span>No variants</span>
      )}
    </div>
  );
};

export default ProductWithVariants;
