import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InformationContainer from './InformationContainer';
import { getUserDetails } from '../actions/userActions';

const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    }
  }, [dispatch, user]);

  const handleEmailClick = () => {
    // Pre-fill the email body with a request to delete the account and placeholder
    const emailBody = `
      Hi De Knal Sales Team,

      I'm writing to request the deletion of my account with ID: ${user && user._id}.

      Please let me know if you require any further information from me to proceed.

      Thanks,
      [Your Name]
    `;

    // Encode the email body for safe inclusion in the URL
    const encodedEmailBody = encodeURIComponent(emailBody);

    // Open a new email window/tab with the recipient pre-filled and the email body set
    window.location.href = `mailto:sales@deknal?subject=Account Deletion Request&body=${encodedEmailBody}`;
  };

  return (
    <div className="container p-10 text-default">
      <div className="flex flex-row">
        <InformationContainer className="w-full bg-red-200 text-default">
          <div className='flex w-full'>
            <Link to='/profile' type="submit" className="btn w-40 mr-auto mb-10 text-default">
              Go Back
            </Link>
          </div>
          <div className='w-1/2'>
            <h2>Delete Account</h2> <br />
            <p>
              To request the deletion of your account, please send an email.
              <br /><br />
            </p>
            <br />
            <button
              type="button"
              className="btn w-40 mt-2 mr-auto"
              onClick={handleEmailClick} // Pass order data to handleEmailClick
              disabled={loading || !user}
            >
              Send Email
            </button>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
          </div>
        </InformationContainer>
      </div>
    </div>
  );
};

export default DeleteAccount;
