import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Message from "../components/message";
import Loader from "../components/loader";
import { listProducts } from "../actions/productActions";

import ProductCarousel from "../components/ProductCarousel";
import ProductsList from "../components/ProductsList";
import TextBox from "../components/TextBox";
import ArtistScreenList from "../components/ArtistScreenList";
import { Link } from "react-router-dom";

const HomeScreen = () => {
  //const { keyword, pageNumber } = useParams();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const version = true; // Change this condition based on your logic

  useEffect(() => {
    dispatch(listProducts()); // Pass the keyword and pageNumber to the listProducts action
  }, [dispatch]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to determine carousel items based on screen size
  const getCarouselItems = () => {
    if (screenSize.width >= 1280) {
      return 3; // xl
    } else if (screenSize.width >= 1024) {
      return 2; // lg
    } else if (screenSize.width >= 768) {
      return 2; // md
    } else {
      return 1; // sm
    }
  };

  const CarouselItems = getCarouselItems();
  const numberOfCards = CarouselItems + 1;

  return (
    <div className="min-h-screen flex flex-col">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {/* Blue and Green Squares */}

          <div className="grid lg:grid-cols-3 lg:grid-rows-1 h-full nav">
            <div className="flex flex-col p-10 lg:col-span-1 h-custom lg:h-full lg:nav-right">
              <div className="flex items-center justify-start text-left ">
                <h1 className="text-default text-5xl">
                  Tailor made products, straight from the artists and designers
                </h1>
              </div>

              <div className="flex flex-col mt-auto">
                <h2 className="text-default text-2xl mt-2">
                  A platform fully stuffed with custom creations
                </h2>
              </div>

              <div className="flex mt-auto">
                <button className="btn w-40 ml-auto">How we work</button>
              </div>
            </div>

            <div className="flex flex-col p-5  h-full col-span-2">
              <ProductCarousel CarouselItems={3} />

              <div className="flex">
                <Link to="/projects" className="btn w-40 ml-auto">
                  See Projects
                </Link>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start h-24 nav-standard nav-left">
            {/* Green square */}
            <div className="px-3 pt-9">
              <p className="text-left text-default text-4xl">New Products</p>
            </div>
            {/* Text inside the green square */}
          </div>

          <div className="flex flex-col w-full h-full">
            <ProductsList products={products} />
          </div>

          <div className="flex items-center justify-start nav-standard nav-left">
            {/* Green square */}
            <div className="px-3 pt-9 pb-3">
              <p className="text-left text-default text-4xl">
                What we are good in at De Knal
              </p>
            </div>
            {/* Text inside the green square */}
          </div>

          {/* about De Knal */}

          {/* Company descriton block */}
          <div className="flex flex-col md:flex-row w-full flex-grow">
            <div className="w-full md:w-1/3 flex-grow bg-blue-500">
              <TextBox
                version={version}
                headerText={
                  <>
                    About De Knal
                    <br />
                    <br />
                  </>
                }
                bodyText={
                  <>
                    We connect craftsmen with customers who are looking for
                    unique custom-made items.
                    <br />
                    <br />
                    Our platform offers live configuration and accurate quotes.
                    Join our network and create beautiful custom products for
                    every home.
                  </>
                }
              />
            </div>
            <div className="w-full md:w-1/3 flex-grow bg-green-500">
              <TextBox
                version={version}
                headerText={
                  <>
                    Creativity
                    <br />
                    <br />
                  </>
                }
                bodyText={
                  <>
                    By ordering items from the creators on our platform, you
                    support a revolutionary concept that enables artisans to
                    showcase their craft and provide customers with unique,
                    custom-made products.
                    <br />
                    <br />
                    You contribute to fostering creativity, craftsmanship, and
                    sustainability. Together, we create beautiful items that
                    perfectly fit your style and needs.
                    <br />
                    <br />
                    Support the creators and experience the magic of bespoke
                    craftsmanship.
                  </>
                }
              />
            </div>

            <div className="w-full md:w-1/3 flex-grow relative flex flex-col justify-end p-10 h-30">
              <div
                className="absolute inset-0"
                style={{
                  backgroundImage: `url('/images/CompanyImages/logoDeKnalRainbowBackNoLogo.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>

              <div className="flex items-start justify-center mb-auto">
                <img
                  src="/images/CompanyImages/logoDeKnalRainbowBackLogo.png"
                  style={{
                    objectFit: "contain",
                    position: "relative",
                    width: "70%",
                    height: "auto",
                  }}
                  alt="Logo"
                />
              </div>

              <Link 
  to="BecomeASeller" 
  className="btn text-default text-white border border-white hover:bg-white hover:text-black z-20 w-40 ml-auto"
>
  Become An Artist
</Link>
            </div>
          </div>

          <div className="flex items-center justify-start nav-standard nav-left">
            {/* Green square */}
          <div className="px-3 pt-9 pb-3">
              <p className="text-left text-default text-4xl">
               
              </p>
            </div>
            {/* Text inside the green square */}
          </div>

          <div className="flex items-center justify-start h-full nav-standard nav-left">
            {/* Green square */}
            {/* <ArtistScreenList CarouselItems={CarouselItems} /> square */}

            {/* Text inside the green square */}
          </div>
        </>
      )}
    </div>
  );
};

export default HomeScreen;
