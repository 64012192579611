import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import { Link, useNavigate } from "react-router-dom";
import { createOrder } from "../actions/orderActions";
import CartTable from "../components/CartTable";
import InformationContainer from "../components/InformationContainer"; // Assuming you have this component

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const font = "font-thin pl-5 text-default";
  const TextTitle = "font-bold text-default";

  const cart = useSelector((state) => state.cart);

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 50 : 10);
  cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
    }
  }, [success, order]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <div className="flex flex-col lg:flex-row h-full w-full nav">
      <div className="w-full lg:w-2/3 p-10 lg:nav-right lg:overflow-y-auto h-full">
      <div>
          {cart.cartItems.length === 0 ? (
            <Message>Your cart is empty</Message>
          ) : (
            <>
              <CartTable cartItems={cart.cartItems} removeFromCartHandler={() => {}} />
              <div className="flex justify-end">
                <Link to="/cart" className="btn w-40 mt-8">
                  Change Cart
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="h-full w-full lg:w-1/3 p-10  nav-top nav-top-hidden">
      <div>
          <InformationContainer>
            <div className={font}>
              <p className="font-bold">Address</p>
              <div className="ml-10 flex flex-col">
                <p>{cart.shippingAddress.postalCode}</p>
                <p>{cart.shippingAddress.city}</p>
                <p>{cart.shippingAddress.country}</p>
              </div>
            </div>
            <Link to="/shipping" className="btn w-40 mt-10 ml-auto">
              Change
            </Link>
          </InformationContainer>
        </div>

        <div className="my-10">
          <InformationContainer>
            <div className={font}>
              <p className={TextTitle}>Payment Method</p>
              <div className={font}>
                <p className="font-normal">Method:</p> {cart.paymentMethod}
              </div>
            </div>
            <Link to="/payment" className="btn w-40 mt-10 ml-auto">
              Change
            </Link>
          </InformationContainer>
        </div>

        <div>
          <InformationContainer>
            <p className={TextTitle}>Summary</p>
            <div className="mt-2 p-2 dropdown space-y-2">
              <div className={` ${font}`}>
                <div className="flex justify-between">
                  <span className="font-normal">Items</span>
                  <span>${cart.itemsPrice}</span>
                </div>

                <div className="flex justify-between">
                  <span className="">Shipping</span>
                  <span>${cart.shippingAddress.timeToDrive * 200}</span>
                </div>

                <div className="flex justify-between">
                  <span className="">Tax</span>
                  <span>${cart.taxPrice}</span>
                </div>

                <div className="flex justify-between mb-2">
                  <span className="font-normal ml-10">Total</span>
                  <span>${cart.totalPrice}</span>
                </div>

                {error && (
                  <div className="mb-0">
                    <Message variant="danger">{error}</Message>
                  </div>
                )}
              </div>
            </div>
          </InformationContainer>
        </div>
        <div className="flex justify-end">
          <button
            className="btn w-40 mt-10 ml-auto"
            disabled={cart.cartItems.length === 0}
            onClick={placeOrderHandler}
          >
            Ask Quote!
          </button>
        </div>
      </div>
     
    </div>
    
    
  );
};

export default PlaceOrderScreen;
