import axios from 'axios';
import {ORDERS_WITH_IMAGES_FAIL, ORDERS_WITH_IMAGES_REQUEST, ORDERS_WITH_IMAGES_SUCCESS} from '../constants/projectContants'


export const fetchOrdersWithImages = () => async (dispatch) => {
  try {
    dispatch({ type: ORDERS_WITH_IMAGES_REQUEST });

    // No authorization header needed for this endpoint
    const { data } = await axios.get('/api/orders/with-images');

    dispatch({
      type: ORDERS_WITH_IMAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDERS_WITH_IMAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};