import axios from 'axios';
import { CART_ADD_ITEM , CART_EMPTY, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS} from '../constants/cartContants';

export const addToCart = (id, qty, selectedVariant, calculatedPrice) => async (dispatch, getState) => {
  try {
    const { data } = await axios.get(`/api/products/${id}`);
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id, // This should be _id if your product identifier is named _id in the data
        name: data.name,
        image: data.image,
        price: calculatedPrice,
        countInStock: data.countInStock,
        qty,
        variant: selectedVariant, // Include the selected variant in the payload
        images: data.images,
        variations: data.variations,
        user: data.user
      
      },
    });
    
    // Now you need to use getState to get the updated state after dispatch
    const { cart: { cartItems } } = getState();
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    
  } catch (error) {
    throw new Error(error);
  }
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id
  })

localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data
  });

  localStorage.setItem('shippingAddress', JSON.stringify(data));
};


export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data
  });

  localStorage.setItem('paymentMethod', JSON.stringify(data));
};


export const emptyCart = () => (dispatch) => {
  dispatch({ type: CART_EMPTY });
};