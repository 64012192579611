import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Message from './message';
import Loader from './loader';
import { ListArtist } from '../actions/userActions';
import ArtistScreenCard from './ArtistScreenCard';

const ArtistScreenList = ({ CarouselItems }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ListArtist());
  }, [dispatch]);

  const artistList = useSelector((state) => state.artistList);
  const { loading, error, users } = artistList;

  // Slice the users array based on the specified number of cards
  const slicedArtists = users.slice(0, CarouselItems);

  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 nav-left">
            {slicedArtists.map((artist) => (
              <ArtistScreenCard key={artist._id} artist={artist} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistScreenList;