import React from 'react';

const FormContainer = ({ children }) => {
  return (
    <div className="flex items-center justify-center text-default py-20">
     <div className="w-full max-w-xl bg-transparent dropdown">
        <div className="w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FormContainer;