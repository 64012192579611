import React from "react";

const TextDefaultTitle = "text-default text-xl text-white";
const TextDefaultLink =
  "title-text text-white font-thin text-xl";

const containerClass = "py-5 flex flex-col justify-between";

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="container mx-auto grid grid-cols-4 gap-8">
       {/* Logo */}
       
       
           

       <div className="flex flex-col items-right justify-end py-5">
  {/* /Users/michielvandeputte/Library/Mobile Documents/com~apple~CloudDocs/webshopDrie/webshopTwee/frontend/public/images/CompanyImages/logoDeKnal.png/} */}
  <img
    src="/images/CompanyImages/logoDeKnalNegative.png"
    alt="De Knal"
    className="object-contain h-20 "
  />
</div>

        {/* Company Information */}
        <div className={`${containerClass}`}>
          <div>
            <h2 className={TextDefaultTitle}>De Knal</h2>
            <ul>
              <li>
              <a href="/aboutus" className={`${TextDefaultLink} `}>
                  About Us
                </a>
              </li>
              <li>
                <a href="/" className={TextDefaultLink}>
                 {/*  Stories */}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className={`${TextDefaultTitle} mt-auto`}>Selling & Buying</h2>
            <ul>
              <li>
                <a href="/BecomeASeller" className={TextDefaultLink}>
                  Become a seller!
                </a>
              </li>
              <li>
                <a href="#" className={TextDefaultLink}>
                  Buying Guide
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Customer Support */}
        <div className={`${containerClass}`}>
          <h2 className={TextDefaultTitle}>Customer Care</h2>
          <ul>
            <li>
              <a href="#" className={TextDefaultLink}>
                Product Support
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Contact Us
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Warrenty
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Shipping Information
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Terms of Service
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Cookie Policy
              </a>
            </li>
            <li>
              <a href="#" className={TextDefaultLink}>
                Terms & Conditions
              </a>
            </li>
          </ul>
        </div>

        {/* Policies and Legal Information */}
        {/* Community and Resources */}
        <div className={`${containerClass}`}>
          <div>
            <h2 className={TextDefaultTitle}>De Knal for business</h2>
            <ul>
              <li>
                <a href="#" className={TextDefaultLink}>
                  Business Enquiries
                </a>
              </li>
              <li>
                <a href="#" className={TextDefaultLink}>
                  Press Enquiries
                </a>
              </li>
              <li>
                <a href="#" className={TextDefaultLink}>
                  Image Bank
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-auto">
            <h2 className={TextDefaultTitle}>Follow Us</h2>
            <div className="flex items-center justify-left gap-4">
              <a href="#" className="inline-block">
                <i className="fab fa-tiktok text-4xl"></i>
              </a>
              <a href="#" className="inline-block">
                <i className="fab fa-instagram text-4xl"></i>
              </a>
            </div>
            <div className="flex items-center justify-left gap-4">
              <a href="#" className="inline-block">
                <i className="fab fa-pinterest text-4xl"></i>
              </a>
              <a href="#" className="inline-block">
                <i className="fab fa-linkedin text-4xl"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
