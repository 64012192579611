// src/utils/generateInvoice.js

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Invoice from '../components/Invoice';

const generateInvoice = async (order) => {
  const blob = await pdf(<Invoice order={order} />).toBlob();
  saveAs(blob, `invoice_${order._id}.pdf`);
};

export default generateInvoice;

