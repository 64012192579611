import React from 'react';
import { Document, Page, Text, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { marginBottom: 2, padding: 2,  },
  heading: { fontSize: 24, marginVertical: 10 ,  fontFamily: 'Helvetica', fontWeight: 'bold'},
  text: { fontSize: 12, marginBottom: 5, fontFamily: 'Helvetica' },
  textDefault: { color: '#333', fontFamily: 'Helvetica', fontSize: 12, marginLeft:10},
  logo: { width: 100, marginBottom: 20 },
  tableHeader: { flexDirection: 'row', borderBottom: '1px solid #000', paddingBottom: 5, marginBottom: 5 },
  tableRow: { flexDirection: 'row', marginBottom: 5 },
  tableCol: { width: '25%', textAlign: 'center' }
});



const formatNumber = (number) => (number !== undefined && number !== null ? number.toString() : 'N/A');

const Invoice = ({ order }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <Image style={styles.logo} src="/images/CompanyImages/logoDeKnal.png" />
        <Text style={[styles.heading]}>Company Information <br/></Text>

        <Text style={[styles.section, styles.textDefault]}>De Knal</Text>
        <Text style={[styles.section, styles.textDefault]}>+316 3914312</Text>
        <Text style={[styles.section, styles.textDefault]}>Sales@deknal.nl</Text>
        <Text style={[styles.section, styles.textDefault]}>www.deknal.nl</Text>
        <Text style={[styles.section, styles.textDefault]}></Text>
        <Text style={[styles.section, styles.textDefault]}></Text>
        <Text style={[styles.section, styles.textDefault]}></Text>
       
        

        <Text style={[styles.heading]}>Order ID: {order._id}</Text>
        <Text style={[styles.section, styles.textDefault]}>
          Order Date: {new Date(order.createdAt).toISOString().split('T')[0]}
        </Text>
        <Text style={[styles.section, styles.textDefault]}></Text>

        <Text style={[styles.heading]}>Shipping Address:</Text>
        
          
          <Text style={[styles.section, styles.textDefault]}>City: {order.shippingAddress.city}</Text>
          <Text style={[styles.section, styles.textDefault]}>Postal Code: {order.shippingAddress.postalCode}</Text>
          <Text style={[styles.section, styles.textDefault]}>Country: {order.shippingAddress.country}</Text>
          <Text style={[styles.section, styles.textDefault]}></Text>
        
        <Text style={[styles.heading]}>Items</Text>
        
        

        <Text style={[styles.section, styles.textDefault]}>
          Subtotal: €{formatNumber(order.totalPrice - order.taxPrice - order.shippingPrice)}
        </Text>
        <Text style={[styles.section, styles.textDefault]}>
          Tax: €{formatNumber(order.taxPrice)}
        </Text>
        <Text style={[styles.section, styles.textDefault]}>
          Shipping: €{formatNumber(order.shippingPrice)}
        </Text>
        <Text style={[styles.section, styles.textDefault]}>
          Total: €{formatNumber(order.totalPrice)}
        </Text>

        <Text style={[styles.section, styles.textDefault]}>
          Payment Method: {order.paymentMethod}
        </Text>
        <Text style={[styles.section, styles.textDefault]}>
          Payment Terms: Net 30 days
        </Text>
        <Text style={[styles.section, styles.textDefault]}>
          Accepted Payment Methods: Bank Transfer, Credit Card, PayPal
        </Text>
        <Text style={[styles.heading]}>Bank Account Details</Text>
        
        
          <Text style={styles.textDefault}>Bank Name: ING</Text>
          <Text style={styles.textDefault}>NL24INGB 0008325292</Text>
          <Text style={styles.textDefault}>DE Knal</Text>
         
        
          <Text style={[styles.section, styles.textDefault]}>
          
        </Text>
          <Text style={[styles.section, styles.textDefault]}>
          
        </Text>
          <Text style={[styles.section, styles.textDefault]}>
          
        </Text>
          <Text style={[styles.section, styles.textDefault]}>
          
        </Text>

        <Text style={[styles.section, styles.textDefault]}>
          Thank you for your business!
        </Text>
      </Page>
    </Document>
  );
};

export default Invoice;
