import React, { useState } from "react";
import { Link } from "react-router-dom";

const ArtistScreenCard = ({ artist }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Using the first image profile as the main image for simplicity
  const mainImage = artist.artistInformation[0].artistImageWithProductA;
  const HooverImage = artist.artistInformation[0].artistImageWithProductB;

  return (
    <Link to={`/artist/${artist._id}`}>
      <div
        className="nav-standard flex flex-col justify-between h-[30rem] cursor-pointer text-default relative transition-opacity"
        style={{
          backgroundImage: `url('${mainImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="p-3 flex justify-center">
          <p className="text-4xl text-white font-thin">
            {artist.artistInformation[0].artistName}
          </p>
        </div>

        <div className="p-3 flex justify-between mt-auto">
          
        </div>

        {/* Overlay content when hovered */}
        {isHovered && (
          <>
            <div
              className="absolute inset-0 ]"
              style={{
                backgroundImage: `url('${HooverImage}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50">
            <div className="text-4xl text-white font-thin">See Artist
            </div>
          
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default ArtistScreenCard;
