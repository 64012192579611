import React from "react";

const ProductPriceTest = ({ product, selectedVariants }) => {
    let basePrice = 100; // Set the default base price to 100
  // Initialize an array to store the formulas
  const formulaStrings = [];
 // Check if product and selectedVariants are defined
 if (!product || !selectedVariants) {
    // Format the base price to currency
    const formattedPrice = basePrice.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return <p>{formattedPrice}</p>; // Display the base price
  }

  console.log(selectedVariants); // Log selected variants to see what we're working with

// Calculate the total price based on the selectedVariants and their corresponding additional prices
let totalPrice = basePrice; // Start with the base price


// Check if product.variations is defined and has at least one item
    if (product.variations && product.variations.length > 0) {


        
   
    }




  // Loop through the variations and add their formulas to the array
  if (product && product.variations) {
    product.variations.slice(0, 1).forEach((variation, index) => {
      const selectedProduct = product.variations[0].options[0]
    console.log(index)
    
    
      const selectedProductOrder = product.variations[0]
        formulaStrings.push(variation.formula);
      const additionalPrice = selectedProduct.additionalPrice; // Assuming the first option is the relevant one
      const formula = eval(selectedProductOrder.formula);
         
      // Construct the formula using the values
        console.log(formula)
   // Construct the formula using the values
        console.log(selectedProduct.additionalPrice)

      

    });
  }

  return (
    <div>
      {/* Display the formula strings */}
      <p> First Variation Formula: {formulaStrings[0]} </p>
      <p>Second Variation Formula: {formulaStrings[1]} </p>
    </div>
  );
};

export default ProductPriceTest;
