import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';


const ToggleLabel = ({ name, isChecked, onChange }) => {
  const handleTofuChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <label className="custom-classname">
      <Toggle
        name={name}
        checked={isChecked}
        onChange={handleTofuChange}
        icons={false}
      />
     
    </label>
  );
};

export default ToggleLabel;
