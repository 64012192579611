import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { getUserDetails } from "../actions/userActions";
import { listMyOrders } from "../actions/orderActions";
import { useOrder } from "../components/Context/OrderContext";
import InformationContainer from "../components/InformationContainer";

const ProfileContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  // Inside ProfileScreen component
  const { setOrderId } = useOrder();

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (!user.name) {
        dispatch(getUserDetails("profile"));
        dispatch(listMyOrders());
      }
    }
  }, [dispatch, navigate, userInfo, user.name]);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col text-default">
        <div className="w-full text-6xl mb-5">
            My De Knal
        </div>

        <div className="grid grid-cols-1 grid-rows-6 sm:grid-cols-2 sm:grid-rows-3 gap-5 w-full">

          <div class="">
            
            <InformationContainer>
              <div className="text-xl font-bold">Profile</div>
              <br />
              <div className="text-xl">User name</div>
              <div className="text-xl font-light">{user.name}</div>
              <br />
              <div className="text-xl">Address</div>
              <div className="ml-10">
                <div className="text-xl font-light">
                  {user.street} {user.streetNumber}  <br />
                  
                
               
                  {user.postalCode} <br /> {user.city}
                </div>
              </div>
              <br />
              <Link to="/profile/edit" className="w-40 btn ml-auto mt-auto">
                Change
              </Link>
            </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className="text-xl font-bold">Inloggegevens</div>
              <br />
              <div className="text-xl">Email</div>
              <div className="text-xl font-light">{user.email}</div>
              <br />
              <div className="text-xl">Password</div>
              <div className="text-xl font-light">********</div>
              <Link
                to="/profile/change-email"
                className="w-40 btn ml-auto mt-auto"
              >
                Change
              </Link>
            </InformationContainer>
          </div>
          <div className="">
          <InformationContainer>
      <div className="text-xl font-bold">Marketing</div>
      <br />

      <div className="text-xl font-light">
        {user.marketingPref === true ? (
          <span>Currently, you are receiving marketing from De Knal.</span>
        ) : (
          <span>Currently, you are not receiving marketing from De Knal.</span>
        )}
      </div>
      <br />

      <div className="text-xl font-light">
        Indicate which marketing communications you would like to receive from De Knal.
      </div>
      <br />

      <Link
        to="/profile/marketing-preferences"
        className="w-40 btn ml-auto mt-auto"
      >
        Change
      </Link>
    </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className="text-xl font-bold">Invoice</div>
              <br />
              <div className="text-xl font-light">
                Your invoice details are provided here.
              </div>
              <br />
              <Link
                to="/profile/digital-invoice"
                className="w-40 btn ml-auto mt-auto"
              >
                Change
              </Link>
            </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className="text-xl font-bold">Reviews</div>
              <br />
              <div className="text-xl font-light">
                You currently have not given any reviews.
              </div>
              <br />
              <Link
                to="/profile/reviews"
                className="w-40 btn ml-auto mt-auto"
              >
                View Reviews
              </Link>
            </InformationContainer>
          </div>
          <div className="">
            <InformationContainer>
              <div className="text-xl font-bold">Delete Account</div>
              <br />
              <div className="text-xl font-light">
                If you delete your account, you will lose all your data and
                this action cannot be undone.
              </div>
              <br />
              <Link
                to="/profile/delete"
                className="w-40 btn ml-auto mt-auto"
              >
                Delete Account
              </Link>
            </InformationContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
