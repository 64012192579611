import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Message from "../components/message";
import Loader from "../components/loader";
import InformationContainer from "./InformationContainer";

import {
  listProducts,
  updateProduct,
  updateProducts // Add updateProducts action import
} from "../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../constants/productConstants";

const ProductListScreen = () => {
  const { pageNumber } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatedPricePerHour, setUpdatedPricePerHour] = useState({});

  const productList = useSelector((state) => state.productList);
  const { error, products } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const { loading: loadingDelete, error: errorDelete } = productDelete;
  const inputStyle = "py-2 px-3 w-1/2 border-black dropdown w-full";

  const productCreate = useSelector((state) => state.productCreate);
  const { loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    } else {
      if (successCreate) {
        navigate(`/admin/product/${createdProduct._id}/edit`);
        dispatch({ type: PRODUCT_CREATE_RESET });
      } else {
        dispatch(listProducts({ user: userInfo._id }));
      }
    }
  }, [dispatch, navigate, userInfo, successCreate, createdProduct]);

  const handlePriceChange = (productId, value) => {
    setUpdatedPricePerHour({
      ...updatedPricePerHour,
      [productId]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedProducts = products.map((product) => {
      return {
        ...product,
        pricePerHour: updatedPricePerHour[product._id] || product.pricePerHour
      };
    });
    console.log(updatedProducts);
    dispatch(updateProduct(updatedProducts));
    navigate("/artists/dashboard");

  };

  return (
    <InformationContainer>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-default">Products</h1>
        </div>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant="danger">{errorDelete}</Message>}
        {loadingCreate && <Loader />}
        {errorCreate && <Message variant="danger">{errorCreate}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        <form onSubmit={handleSubmit}>
          <div className="nav">
            <table className="table-auto w-full text-default">
              <thead>
                <tr>
                  <th className="px-2 py-2 ">ID</th>
                  <th className="py-2">NAME</th>
                  <th className="px-4 py-2">PRICE / H</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product._id} className="nav-top">
                    <td className="px-4 py-2">{product._id}</td>
                    <td className="px-4 py-2">{product.name}</td>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        //display as a spaceholder the old value
                        placeholder={product.pricePerHour}
                        
                        onChange={(e) => handlePriceChange(product._id, e.target.value)}
                        className={inputStyle}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex w-full mt-4">
            <button
              type="submit"
              className="btn w-40 ml-auto mt-auto"
            >
              Update
            </button>
          </div>
        </form>
        
      </div>
      
    </InformationContainer>
  );
};

export default ProductListScreen;
