//this is the custom channel header function

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOrder } from '../Context/OrderContext';
import { getOrderDetails } from '../../actions/orderActions';
import { listProductDetails } from '../../actions/productActions';

const CustomChannelHeader = () => {
  const { orderId } = useOrder();
  const dispatch = useDispatch();

  // Access order and product details from Redux state
  const orderDetails = useSelector((state) => state.orderDetails);
  const productDetails = useSelector((state) => state.productDetails);

  const { loading: orderLoading, error: orderError, order } = orderDetails;
  const { loading: productLoading, error: productError, product } = productDetails;

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    const firstProductId = order?.orderItems?.[0]?.product;
    if (firstProductId) {
      dispatch(listProductDetails(firstProductId));
    }
  }, [dispatch, order?.orderItems]);

  // Access 'isPaid' and 'isDelivered' properties
  const itemsCount = order?.orderItems?.length || 0;
  const productNames = order?.orderItems?.map(item => item.name).join(', ') || 'No products';
  const isPaid = order?.isPaid || false;
  const isDelivered = order?.isDelivered || false;

  // Access the user ID directly for rendering
  const productUserId = product?.user;

  return (
    <div className="custom-channel-header">
      {orderLoading ? (
        <h2>Loading order details...</h2>
      ) : orderError ? (
        <h2>Error: {orderError}</h2>
      ) : (
        <>
          <h2>Order: {orderId}</h2>
          <p>Items: {itemsCount}</p>
          <p>Products: {productNames}</p>
          <p>Paid: {isPaid ? 'Yes' : 'No'}</p>
          <p>Delivered: {isDelivered ? 'Yes' : 'No'}</p>
          {productLoading ? (
            <p>Loading product details...</p>
          ) : productError ? (
            <p>Error: {productError}</p>
          ) : (
            productUserId && <p>Product User ID: {productUserId}</p> // Display the user ID only when available
          )}
        </>
      )}
    </div>
  );
};

export default CustomChannelHeader;