import React, { useEffect } from 'react';
import CardProjects from '../components/cardProjects';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrdersWithImages } from '../actions/projectActions';

const ProjectListScreen = () => {
  const dispatch = useDispatch();

  const orderList = useSelector((state) => state.ordersWithImages);
  const { loading, error, orders: ordersWithImages } = orderList;

  useEffect(() => {
    dispatch(fetchOrdersWithImages());
  }, [dispatch]);


  const widthCombos1 = ['w-full lg:w-1/3', 'w-full lg:w-2/3'];
  const widthCombos2 = ['w-full lg:w-2/3', 'w-full lg:w-1/3'];
  const widthCombos3 = ['w-full lg:w-1/2', 'w-full lg:w-1/2'];

  return (
  
  
  <div className="container mx-auto">
      <div className="grid lg:grid-cols-2 grid-rows-1 w-full h-full nav">
        <div className=" flex flex-col justify-left items-top p-5">
          <p className="text-default text-6xl">All projects, made by our artists</p>
        </div>
        <div className="lg:nav-left justify-left items-top p-5">
          <p className="text-default text-2xl">{ordersWithImages.length} projects, all made different</p>
          <p className="text-default font-thin text-2xl">And all made with love</p>
        </div>
      </div>
      {/* Conditionally render CardProjects based on loading/error state */}
      {loading ? (
        <p>Loading projects...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        ordersWithImages.map((project, index) => {
          // Determine which width combination to use based on the index or any other condition
          const widthCombo = index % 3 === 0 ? widthCombos1 : (index % 3 === 1 ? widthCombos2 : widthCombos3);
          return (
            <CardProjects key={project._id} project={project} widthCombo={widthCombo} orderList={orderList} />
          );
        })
      )}
    </div>
  );
};

export default ProjectListScreen;
