import { CHAT_TOKEN_FAILURE, CHAT_TOKEN_REQUEST, CHAT_LOGOUT, CHAT_TOKEN_SUCCESS } from "../constants/StreamConstants";


// chatReducer.js

const initialState = {
    token: null,
    isAuthenticated: false,
    loading: false,
    error: null,
  };
  
  export const chatReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHAT_TOKEN_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case CHAT_TOKEN_SUCCESS:
        return {
          ...state,
          token: action.payload,
          isAuthenticated: true,
          loading: false,
          error: null,
        };
      case CHAT_TOKEN_FAILURE:
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: false,
          error: action.payload,
        };
      case CHAT_LOGOUT:
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: false,
          error: null,
        };
      default:
        return state;
    }
  };