import React from 'react';
import { useParams } from 'react-router-dom';
import useArtistDetails from '../hooks/useArtistDetails';
import Message from '../components/message'; // Import custom Message component
import Loader from '../components/loader'; // Import custom Loader component

const ArtistScreenTest = () => {
  const { artistId } = useParams(); // Extract artist ID from URL params
  const { loading, error, artist } = useArtistDetails("66028ccb3dd79b903adee710");

  return (
    <div>
      {loading && <Loader />} {/* Display custom loader if loading */}
      {error && <Message variant="danger">{error}</Message>} {/* Display custom message if error */}
      {!loading && !error && artist && artist.artistInformation && artist.artistInformation.length > 0 && (
        <div>
          <div className="flex w-full justify-start h-[22rem]">
            <div className="blok1 nav-left nav-standard w-2/3 p-5 relative">
              {/* Content for the left column */}
              <p className="text-default text-6xl">
                {artist.artistInformation[0].artistName}
              </p>
              <p className="text-default text-xl font-thin">
                {artist.artistInformation[0].artistBio}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtistScreenTest;
