import React from "react";

const AboutUs = () => {
  return (
    <div className="w-full nav-standard nav-left nav-top pt-20">
      <div>
        <div className="flex justify-center pb-20">
          <p className="text-default text-6xl text-center w-1/3">
            Rethinking tailor made products
          </p>
        </div>



        <div className="flex justify-center text-default text-2xl mt-8">
          <p className="font-thin w-2/3">
            De Knal is a platform that connects customers with talented
            manufacturing companies to create unique, custom-made furniture and
            objects.
            <br />
            <br />
            At De Knal, you can configure your own designs and have them made
            directly by affiliated local manufacturing companies and artists.
            <br />
            <br />
            The platform utilizes advanced technology to make the production
            process more efficient and precise, ensuring that you always receive
            a perfectly fitting and high-quality product.
          </p>
        </div>
      </div>

      



    

      <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:grid-rows-2 pt-20 nav-bottom">
        {/* red container with half width */}
        
        <div className="w-full flex flex-col justify-end p-10 gap-3 nav-standard nav-top">
          <p className="text-default text-6xl mt-40">Why De Knal</p>
          <div>
            <p className="text-default text-2xl">Unique Designs</p>
            <p className="text-default text-2xl font-thin w-full xl:w-2/3">
              At De Knal, customers have access to unique, custom-made products.
              Each piece is a creation of talented artists who combine their
              artistic vision with specific customer requirements.
            </p>
          </div>
          <div>
            <p className="text-default text-2xl">Craftsmanship Quality</p>

            <p className="text-default text-2xl font-thin w-full xl:w-2/3">
              Each product is crafted with care and precision, ensuring that you
              always receive a durable and perfectly finished piece of furniture
              that will last a lifetime.
            </p>
          </div>
        </div>
        <div
          className="w-full flex justify-center items-center  nav-bottom nav-top bg-red-500"
          style={{
            backgroundImage: `url(/images/Screens/AboutUs/Grinding.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>

        <div
          className="w-full flex justify-center items-center  nav-standard"
          style={{
            backgroundImage: `url(/images/Screens/AboutUs/productHighLight.jpeg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>

        <div className="w-full flex flex-col justify-end p-10 gap-3  nav-under">
          <p className="text-default text-6xl mt-40">Working De Knal way</p>
          <div className="pl-10">
            <div className="py-2">
              <p className="text-default text-2xl">Simplicity of Process</p>
              <p className="text-default text-2xl font-thin w-full">
                From configuration to delivery, here's a step-by-step
                explanation.
              </p>
            </div>

            <div className="py-2">
              <p className="text-default text-2xl">Configuration</p>
              <p className="text-default text-2xl font-thin w-full">
                Customize your design to your preferences using our intuitive
                online configurator.
              </p>
            </div>

            <div className="py-2">
              <p className="text-default text-2xl">Quotation</p>
              <p className="text-default text-2xl font-thin w-full">
                Request a quotation instantly and see the price and
                specifications of your custom-made product.
              </p>
            </div>

            <div className="py-2">
              <p className="text-default text-2xl">Production</p>
              <p className="text-default text-2xl font-thin w-full">
                Through the efficient way of working at De Knal, your design is
                transformed into workable information for the artists who brings
                your ideas to life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
