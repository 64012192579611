import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../actions/userActions";
import DropdownMenu from "./DropdownMenu";

const Header = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const userMenuItems = [
    { label: "Profile", route: "/profile" },
    { label: "Favorites", route: "/favorites" },
    { label: "Recently Viewed", route: "/recentlyviewed" },
    { label: "My Reviews", route: "/myreviews" },
    { label: "Log Out", route: "/", onClick: logoutHandler },
  ];

  const adminMenuItems = [
    { label: "Users", route: "/admin/userlist" },
    { label: "Products", route: "/admin/productlist" },
    { label: "Orders", route: "/admin/orderlist" },
  ];

 

  const artistMenuItems = [
    { label: "Artist Dashboard", route: "/artists/dashboard" },
    { label: "Manage Products", route: "/artists/artworks" },
  ];

  return (
    <>
      {/* start the code with a spacer of h20 */}
      <nav className="fixed nav-bottom py-2 w-full z-10 bg-white">
        <div className="container mx-auto">
          <div className="flex items-center justify-between h-20">
            {/* Logo and new links */}
            <div className="flex items-center font-bold">
              <Link to="/">
                <div
                  className="size-20 mr-4 bg-contain bg-center bg-repeat-no-repeat"
                  style={{
                    backgroundImage: `url("/images/CompanyImages/logoDeKnal.png")`,
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Link>
              <ul className="flex items-left flex-col lg:flex-row">
                <li className="mr-4">
                  <Link to="/artists" className="titel-text hidden">
                    Artists
                  </Link>
                </li>
                <li className="mr-4">
                  <Link to="/projects" className="titel-text">
                    Projects
                  </Link>
                </li>
                <li className="mr-4">
                  <Link to="/products" className="titel-text">
                    Products
                  </Link>
                </li>
              </ul>
            </div>
            {/* End of Logo and new links */}

            {/* Other links */}
            <div className="hidden lg:block">
              <ul className="flex items-center">
                <li className="ml-4">
                  <Link to="/cart" className="titel-text">
                    Cart
                  </Link>
                </li>
                <li className="ml-4">
                  {userInfo ? (
                    <>
                      <DropdownMenu menuItems={userMenuItems} label={userInfo.name} />
                      {userInfo.isAdmin && (
                        <DropdownMenu menuItems={adminMenuItems} label="Admin" />
                      )}
                      {userInfo.isArtist && (
                        <DropdownMenu menuItems={artistMenuItems} label="Artist Page" />
                      )}
                    </>
                  ) : (
                    <Link to="/login" className="titel-text">
                      <i className="fas fa-user"></i> Sign In
                    </Link>
                  )}
                </li>
              </ul>
            </div>
            {/* End of Other links */}

            {/* Responsive menu icon for small screens */}
            <div className="block lg:hidden">
              <button>
                <i className="fas fa-bars"></i>
              </button>
            </div>
            {/* End of Responsive menu icon */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
